import React from 'react'

function Includes() {
    return(
        <>
            <div style={{backgroundColor:"rgb(247,247,247)", marginTop:"10%", display:"flex", justifyContent:"left", width:"100%", padding:"5% 0%", borderRadius:"5px"}}>
                <div style={{padding:"0 4%", minWidth:"100%"}}>
                    <div style={{fontFamily:"Montserrat", letterSpacing:"6px", textAlign:"left", color:"grey", fontSize:"13px"}}>INCLUDES</div>
                    <div style={{paddingTop:"10px", minWidth:"100%"}}>
                        <div style={{float:"left", maxWidth:"50%", minWidth:"50%"}}>
                        <div style={{display:"flex", flexDirection:"row"}}>
                                <svg style={{minWidth:"15px",maxWidth:"17px", height:"20px", fill:"rgb(200, 204, 207)"}} viewBox="0 0 24 24"><path d="M10 20v-6h4v6h5v-8h.999c1.105 0 1.33-.604.515-1.338l-7.77-6.993a1.136 1.136 0 00-1.487 0l-7.77 6.993C2.664 11.402 2.9 12 4 12h1v8h5z"></path></svg>
                                <div style={{fontSize:"15px", paddingLeft:"10px"}}>Accommodation</div>
                            </div>
                            <div style={{display:"flex", flexDirection:"row"}}>
                                <svg style={{minWidth:"15px",maxWidth:"17px", height:"20px", fill:"rgb(200, 204, 207)"}} viewBox="0 0 20 20"><path clip-rule="evenodd" d="M4.6 2.62C4.6 1.725 5.325 1 6.22 1h7.56c.895 0 1.62.725 1.62 1.62v14.76A1.62 1.62 0 0113.78 19H6.22a1.62 1.62 0 01-1.62-1.62V2.62zm1.8 1.26c0-.597.483-1.08 1.08-1.08h5.04c.596 0 1.08.483 1.08 1.08v9.54a1.08 1.08 0 01-1.08 1.08H7.48a1.08 1.08 0 01-1.08-1.08V3.88zM10 18.1a.9.9 0 100-1.8.9.9 0 000 1.8z" fill-rule="evenodd"></path></svg>
                                <div style={{fontSize:"15px", paddingLeft:"10px"}}>Support</div>
                            </div>
                            <div style={{display:"flex", flexDirection:"row"}}>
                                <svg style={{minWidth:"15px",maxWidth:"17px", height:"20px", fill:"rgb(200, 204, 207)"}} viewBox="0 0 24 24"><path d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zm5.087 7.24a1.326 1.326 0 00-1.846.323l-4.637 6.594-2.266-2.687a1.326 1.326 0 00-2.026 1.709l3.375 4a1.327 1.327 0 002.097-.092l5.625-8a1.325 1.325 0 00-.322-1.846z" fill-rule="evenodd"></path></svg>
                                <div style={{fontSize:"15px", paddingLeft:"10px"}}>Free Cancellation</div>
                            </div>
                            <div style={{display:"flex", flexDirection:"row"}}>
                                <svg style={{minWidth:"15px",maxWidth:"17px", height:"20px", fill:"rgb(200, 204, 207)"}} viewBox="0 0 24 24"><g fill-rule="evenodd"><path d="M14.577 9.94c-.028.041-.037.09-.055.134l-.416.152V8.594c.112.025.222.061.324.113l.717.37-.57.862zm-4.769 1.85l-.97.354-.343-1.633 1.013-1.403c.083-.114.188-.207.3-.288v2.97zm12.586-3.756a1.816 1.816 0 00-2.407-1.379l-.09.033A4.43 4.43 0 0017.9 8.15l-.74.965-.422.153.045-.067a.72.72 0 00-.27-1.032l-1.428-.735a2.75 2.75 0 00-1.26-.306H10.58c-.882 0-1.717.427-2.233 1.142L7.15 9.93a.712.712 0 00-.12.566l.448 2.143-1.068.388-1.163-.256a4.43 4.43 0 00-2.469.162c-.85.31-1.426 1.176-1.257 2.065a1.816 1.816 0 002.407 1.38l.091-.034a4.424 4.424 0 001.996-1.463l.742-.963 1.183-.43a.706.706 0 00.314.082.703.703 0 00.644-.432l.911-.33v1.033c.922-.36 1.59-.704 1.602-.71l.546-.285.548.285c.013.006.68.35 1.602.71v-2.6l.758-.276a.708.708 0 00.908-.236l.082-.124 1.651-.6 1.163.255a4.433 4.433 0 002.47-.162c.85-.309 1.425-1.177 1.256-2.065zM11.957 5.821a1.916 1.916 0 001.911-1.91c0-1.051-.86-1.911-1.91-1.911-1.05 0-1.91.86-1.91 1.911 0 1.051.86 1.91 1.91 1.91m7.305 13.226c-2.228 1.563-5.078 1.563-7.306 0-2.228 1.563-5.077 1.563-7.305 0-1.114.777-2.383 1.206-3.652 1.206v2.107a7.82 7.82 0 003.652-.924c2.228 1.197 5.077 1.197 7.305 0 2.228 1.197 5.078 1.197 7.306 0a7.82 7.82 0 003.652.924v-2.107c-1.269 0-2.538-.428-3.652-1.206"></path><path d="M6.158 16.037s.279 1.375.883 2.502c1.361.382 2.816.15 4.055-.72l.861-.602.861.603c1.24.869 2.695 1.1 4.056.719.604-1.127.883-2.502.883-2.502-2.694-.246-5.8-1.705-5.8-1.705s-3.105 1.459-5.799 1.705"></path></g></svg>
                                <div style={{fontSize:"15px", paddingLeft:"10px"}}>Activities</div>
                            </div>
                        </div>
                        <div style={{float:"right", maxWidth:"50%", minWidth:"50%"}}>
                            <div style={{display:"flex", flexDirection:"row"}}>
                                <svg style={{minWidth:"15px",maxWidth:"17px", height:"20px", fill:"rgb(200, 204, 207)"}} viewBox="0 0 24 24"><path d="M2.5 19h19v2h-19zm19.57-9.36c-.21-.8-1.04-1.28-1.84-1.06L14.92 10l-6.9-6.43-1.93.51 4.14 7.17-4.97 1.33-1.97-1.54-1.45.39 1.82 3.16.77 1.33 1.6-.43 5.31-1.42 4.35-1.16L21 11.49c.81-.23 1.28-1.05 1.07-1.85z" fill-rule="evenodd"></path></svg>
                                <div style={{fontSize:"15px", paddingLeft:"10px"}}>Flights</div>
                            </div>
                            <div style={{display:"flex", flexDirection:"row"}}>
                                <svg style={{minWidth:"15px",maxWidth:"17px", height:"20px", fill:"rgb(200, 204, 207)"}} viewBox="0 0 24 24"><path d="M8.1 13.34l2.83-2.83L3.91 3.5a4.008 4.008 0 000 5.66l4.19 4.18zm6.78-1.81c1.53.71 3.68.21 5.27-1.38 1.91-1.91 2.28-4.65.81-6.12-1.46-1.46-4.2-1.1-6.12.81-1.59 1.59-2.09 3.74-1.38 5.27L3.7 19.87l1.41 1.41L12 14.41l6.88 6.88 1.41-1.41L13.41 13l1.47-1.47z"></path></svg>
                                <div style={{fontSize:"15px", paddingLeft:"10px"}}>Food</div>
                            </div>
                            <div style={{display:"flex", flexDirection:"row"}}>
                                <svg style={{minWidth:"15px",maxWidth:"17px", height:"20px", fill:"rgb(200, 204, 207)"}} viewBox="0 0 24 24"><g fill-rule="evenodd"><path d="M6.29 13.679a2.603 2.603 0 00-2.6 2.6c0 1.434 1.166 2.6 2.6 2.6 1.434 0 2.6-1.166 2.6-2.6 0-1.433-1.166-2.6-2.6-2.6m11.154 0a2.603 2.603 0 00-2.6 2.6c0 1.434 1.166 2.6 2.6 2.6 1.434 0 2.6-1.166 2.6-2.6 0-1.433-1.166-2.6-2.6-2.6"></path><path d="M6.069 10.255H4.111l1.377-2.544h.581v2.544zm15.426 4.037v-3.381l-5.914-.537-1.457-4.576-1.43.455 1.664 5.223-.686 1.316H9.993l-.821-2.527H7.569V5.75a.75.75 0 00-1.5 0v.461H5.042a.748.748 0 00-.66.393l-1.981 3.661H1.5v6.013h1.009a3.784 3.784 0 013.781-3.78 3.783 3.783 0 013.78 3.78h3.593a3.784 3.784 0 013.781-3.78 3.784 3.784 0 013.78 3.78h1.357v-1.986h-1.086z"></path></g></svg>
                                <div style={{fontSize:"15px", paddingLeft:"10px"}}>Communte</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Includes