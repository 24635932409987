import React, { useEffect } from "react";
import '../styles/Form.scss'
import $ from 'jquery'

function Form(){
    useEffect(()=>{
        $(document).ready(function(){
            $('.popup-btn').click(function(){ 
              var popupBlock = $('#'+$(this).data('popup'));
              popupBlock.addClass('active')
                .find('.fade-out').click(function(){
                  popupBlock.css('opacity','0').find('.popup-content').css('margin-top','350px');        
                  setTimeout(function(){
                    $('.popup').removeClass('active');
                    popupBlock.css('opacity','').find('.popup-content').css('margin-top','');
                  }, 600);
                });
           });
          });
    })
    return(
        <>
            <div style={{marginBottom:"5%", minHeight:"100px"}}>
            {/* <h1>Questions ?</h1> */}
            {/* <h2>"Because you will never know how absolutely perfect something could turn out to be"</h2> */}
            <button style={{position:"relative", marginTop:"15%"}} type="button" class="main-btn-rect popup-btn" data-popup="popup-reg">Contact Us!</button>
            <div id="popup-reg" class="popup">
            <div class="popup-content">
                <div class="event-header">
                <h6>Don't be shy, say hi!</h6>
                </div>
                <form id="send" class="send-form">
                    <div class="form-group">
                    <input type="text" placeholder="Enter name" id="name" name="name" required="required"/>
                    <label for="name">
                        <i class="fa fa-user"></i>
                    </label>
                    </div>
                    <div class="form-group">
                    <input type="tel" placeholder="Enter phone" id="phone" name="phone" required="required"/>
                    <label for="phone">
                        <i class="fa fa-phone"></i>
                    </label>
                    </div>
                    <div class="form-group">
                    <input type="email" placeholder="Enter mail" id="mail" name="mail" required="required"/>
                    <label for="mail">
                        <i class="fa fa-envelope"></i>
                    </label>
                    </div>
                    <div class="form-group">
                    <textarea placeholder="Enter message" name="text" id="text"></textarea>
                    <label for="text" class="txt">
                        <i class="fa fa-commenting"></i>
                    </label>
                    </div>
                    <button type="submit" class="main-btn-rect" name="text" value="Send">
                    <i class="fa fa-paper-plane"></i>Send</button>
                </form>
                <span class="fade-out main-btn-circle">╳</span>
            </div>
            </div>
            </div>
            {/* <div class="share-wrap">
            <a href="http://yubutsan.com.ua/" class="share-btn" rel="nofollow" target="_blank">Author Page</a>
            <a class="resp-sharing-button__link" href="https://facebook.com/sharer/sharer.php?u=https://codepen.io/0neLife/full/jKPMwE" target="_blank" rel="nofollow" aria-label="">
                <div class="resp-sharing-button resp-sharing-button--facebook resp-sharing-button--small">
                <div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18.77 7.46H14.5v-1.9c0-.9.6-1.1 1-1.1h3V.5h-4.33C10.24.5 9.5 3.44 9.5 5.32v2.15h-3v4h3v12h5v-12h3.85l.42-4z"/></svg>
                </div>
                </div>
            </a>
            <a class="resp-sharing-button__link" href="https://twitter.com/intent/tweet/?text=[%20Must%20Have%20]%20Responsive%20Popup%20Contact%20Form%20with%20jQuery&amp;url=https://codepen.io/0neLife/full/jKPMwE" target="_blank" aria-label="">
                <div class="resp-sharing-button resp-sharing-button--twitter resp-sharing-button--small"><div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.44 4.83c-.8.37-1.5.38-2.22.02.93-.56.98-.96 1.32-2.02-.88.52-1.86.9-2.9 1.1-.82-.88-2-1.43-3.3-1.43-2.5 0-4.55 2.04-4.55 4.54 0 .36.03.7.1 1.04-3.77-.2-7.12-2-9.36-4.75-.4.67-.6 1.45-.6 2.3 0 1.56.8 2.95 2 3.77-.74-.03-1.44-.23-2.05-.57v.06c0 2.2 1.56 4.03 3.64 4.44-.67.2-1.37.2-2.06.08.58 1.8 2.26 3.12 4.25 3.16C5.78 18.1 3.37 18.74 1 18.46c2 1.3 4.4 2.04 6.97 2.04 8.35 0 12.92-6.92 12.92-12.93 0-.2 0-.4-.02-.6.9-.63 1.96-1.22 2.56-2.14z"/></svg>
                </div>
                </div>
            </a>
            <a class="resp-sharing-button__link" href="https://www.linkedin.com/shareArticle?mini=true&amp;url=https://codepen.io/0neLife/full/jKPMwE&amp;title=[%20Must%20Have%20]%20Responsive%20Popup%20Contact%20Form%20with%20jQuery&amp;source=http%3A%2F%2Fsharingbuttons.io" target="_blank" aria-label="">
                <div class="resp-sharing-button resp-sharing-button--linkedin resp-sharing-button--small"><div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M6.5 21.5h-5v-13h5v13zM4 6.5C2.5 6.5 1.5 5.3 1.5 4s1-2.4 2.5-2.4c1.6 0 2.5 1 2.6 2.5 0 1.4-1 2.5-2.6 2.5zm11.5 6c-1 0-2 1-2 2v7h-5v-13h5V10s1.6-1.5 4-1.5c3 0 5 2.2 5 6.3v6.7h-5v-7c0-1-1-2-2-2z"/></svg>
                </div>
                </div>
            </a>
            </div> */}
        </>
    )
}

export default Form