import React from 'react'
// ZZAA
// import logoPng from '../assets/logo1.png'
// import darkPng from '../assets/Horizontal Logo.png'
import logoPng from '../assets/Horizontal L.png'
import darkPng from '../assets/Horizontal L.png'

function Header({dark = false}) {
    const logoSrc = dark ? darkPng : logoPng;
    return(
        <div>
            <div style={{position:"fixed", top:"0", left:"0", minWidth:"100%", boxShadow: "rgba(0, 0, 0, 0.1) 0px -20px 13px 16px", zIndex:"999999" }}>
            </div>
            {/* ZZAA */}
            <div style={{position:"absolute", top:"0", left:"0", maxWidth:"100%", padding:"10px", zIndex:"9999", display:"flex", justifyContent:"center", backgroundColor:"white", minWidth:"100%", display:"flex", justifyContent:"flex-start"}}>
            {/* <div style={{position:"absolute", top:"0", left:"0", maxWidth:"100%", padding:"10px", zIndex:"9999", display:"flex", justifyContent:"center"}}> */}
                <a href='/'>
                    <img loading="lazy" className='header-logo' src={logoSrc}/>
                </a>
                {/* <div style={{position:"absolute", top:"10%", left:"80%", padding:"20px", zIndex:"9999", maxWidth:"50%"}}>
                    <svg style={{fill:"white"}} width="1em" height="1em" viewBox="0 0 24 24" type="button" aria-haspopup="dialog" aria-expanded="false" aria-controls="radix-0" data-state="closed" class="PJLV PJLV-ifiGNBN-css"><path fill-rule="evenodd" d="M22 20.889c0 .614-.497 1.111-1.111 1.111H3.11a1.111 1.111 0 010-2.222H20.89c.614 0 1.111.497 1.111 1.11zm-5.23-4.77a1.111 1.111 0 010-1.571L19.317 12 16.77 9.452a1.111 1.111 0 011.571-1.571l3.334 3.333a1.111 1.111 0 010 1.572l-3.334 3.333a1.111 1.111 0 01-1.571 0zM15.535 12c0 .614-.497 1.111-1.11 1.111H3.11a1.111 1.111 0 010-2.222h11.313c.614 0 1.111.497 1.111 1.111zM22 3.111c0 .614-.497 1.111-1.111 1.111H3.11a1.111 1.111 0 110-2.222H20.89C21.503 2 22 2.497 22 3.111z" clip-rule="evenodd"></path></svg>
                </div> */}
            </div>
        </div>
    )
}

export default Header