import React from "react";
import '../styles/Homepage.scss'

function HomepageSlider ({dataSlider, title, subtitle}){
    return(
        <>
            <div className="homepage-slider-section">
                {title && 
                    <h2 style={{textAlign:"left", paddingLeft:"5%", color:"#3f4144", textTransform:"uppercase", fontFamily:"Lato", fontWeight:"700"}} className="font-size-32-20">
                        {title}
                    </h2>
                }
                {subtitle &&
                    <p style={{textAlign:"center", color:"grey", fontSize:"18px"}}>{subtitle}</p>
                }
                    <div style={{width: "100%",whiteSpace: "nowrap",overflowX: "auto", display:"flex", flexDirection:"row"}} className="homepage-img-section">
                        {dataSlider.map((data ,index) => (
                            <a href={`/search/${data.title}`}> 
                                <img loading="lazy" className="homepage-slider-img shadow-on-hover" src={data.img} />
                            </a>
                        ))}
                    </div>
            </div>
        </>
    )
}

export default HomepageSlider