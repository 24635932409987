import React from "react";

function HomepageBackgroundColorSection({title, description, data}){
    return(
        <div className="display-mobile" style={{backgroundColor:"#121b22", padding:"5% 0 50px 0"}}>
            <h2 style={{textAlign:"center", color:"#2acc65", fontWeight:"700"}}>
                {title}
            </h2>
            <p style={{textAlign:"center", color:"#2acc65", fontSize:"18px"}}>{description}</p>
            {/* <div className="row">
            <div className="col"> */}
                {/* <Slider {...slide3} className="slide-3 arrow-classic"> */}
            <div style={{display:"flex",justifyContent:"center", alignContent:'center'}}>
                <div className="bg-img-width" style={{ whiteSpace: "normal", textAlign: "center", display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
                    {data.map((data, index) => (
                        <div key={index} style={{ flex: "1 0 30%", margin: "5px" }}>
                        <div>
                            <img loading="lazy" src={data.img} className="img-fluid bg-img bg-color-imgs" style={{ borderRadius: "20px", objectFit: "cover", display: "block", margin: "auto" }} />
                            <div style={{ paddingTop: "3%", color: "#2acc65", textTransform: "capitalize", textAlign: "center" }}>{data.title}</div>
                        </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default HomepageBackgroundColorSection