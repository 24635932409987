import React from "react";

function HomepageBackgroundColorSectionWeb({title, description, data}){
    return(
        <div className="display-web" style={{backgroundColor:"#121b22", padding:"5% 0 0% 0"}}>
            <h2 style={{textAlign:"center", color:"#2acc65", fontWeight:"700"}}>
                {title}
            </h2>
            <p style={{textAlign:"center", color:"#2acc65", fontSize:"18px"}}>{description}</p>
            <div style={{display:"flex",justifyContent:"center", alignContent:'center', margin:"0 15%", minHeight:"55vh"}}>
                <div style={{ whiteSpace: "normal", textAlign: "center", display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
                    {/* {data.map((data, index) => (
                        <div key={index} style={{ flex: "1 0 30%", margin: "5px" }}>
                        <div>
                            <img loading="lazy" src={data.img} className="img-fluid bg-img bg-color-imgs" style={{ borderRadius: "20px", objectFit: "cover", display: "block", margin: "auto" }} />
                            <div style={{ paddingTop: "3%", color: "#2acc65", textTransform: "capitalize", textAlign: "center" }}>{data.title}</div>
                        </div>
                        </div>
                    ))} */}
                    <div style={{minWidth:"100%", display:"flex", flexDirection:"row", justifyContent:"space-between", alignContent:"center", maxHeight:"350px", minWidth:"65vw", maxWidth:"80vw", marginTop:"2%"}}>
                        <div style={{display:"flex", flexDirection:"column", justifyContent:"space-between"}}>
                            <div>
                                <div style={{top:"0", left:"0", width:"100%", position:"relative" }}>
                                    <img loading="lazy" src={data[0].img} style={{minWidth:"360px", maxWidth:"360px",minHeight:"160px", maxHeight:"160px", borderRadius: "20px", objectFit: "fill" }} />
                                    <div style={{position:"absolute", top:"0", left:"0", width:"100%", height:"100%", background:"rgba(0, 0, 0, 0.4)", pointerEvents:"none", borderRadius: "20px"}}></div>
                                    <div style={{position:"absolute", top:"56%", left:"5%", color:"white", fontFamily:"Lato", fontWeight:"900", fontSize:"22px", lineHeight:"1em", textAlign:"left"}}>{data[0].title}<br/> 
                                        <span style={{fontWeight:"400", fontSize:"15px"}}>From ${data[0].price}</span>
                                    </div>
                                </div>
                            </div>
                            <div style={{display:"flex", justifyContent:"space-between"}}>
                                <div style={{top:"0", left:"0", width:"100%", position:"relative",maxWidth:"155px", minWidth:"155px" }}>
                                    <img loading="lazy" src={data[1].img} style={{maxWidth:"155px", minWidth:"155px", minHeight:"160px", maxHeight:"160px", borderRadius: "20px", objectFit: "fill" }} />
                                    <div style={{position:"absolute", top:"0", left:"0", width:"100%", height:"100%",maxWidth:"155px", minWidth:"155px", background:"rgba(0, 0, 0, 0.4)", pointerEvents:"none", borderRadius: "20px"}}></div>
                                    <div style={{position:"absolute", top:"56%", left:"5%", color:"white", fontFamily:"Lato", fontWeight:"900", fontSize:"22px", lineHeight:"1em", textAlign:"left"}}>{data[1].title}<br/> 
                                        <span style={{fontWeight:"400", fontSize:"15px"}}>From ${data[1].price}</span>
                                    </div>
                                </div>
                                <div style={{top:"0", left:"0", width:"100%", position:"relative",maxWidth:"155px", minWidth:"155px", }}>
                                    <img loading="lazy" src={data[2].img} style={{maxWidth:"155px", minWidth:"155px", minHeight:"160px", maxHeight:"160px", borderRadius: "20px", objectFit: "fill" }} />
                                    <div style={{position:"absolute", top:"0", left:"0", width:"100%", height:"100%",maxWidth:"155px", minWidth:"155px", background:"rgba(0, 0, 0, 0.4)", pointerEvents:"none", borderRadius: "20px"}}></div>
                                    <div style={{position:"absolute", top:"56%", left:"5%", color:"white", fontFamily:"Lato", fontWeight:"900", fontSize:"22px", lineHeight:"1em", textAlign:"left"}}>{data[2].title}<br/> 
                                        <span style={{fontWeight:"400", fontSize:"15px"}}>From ${data[2].price}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{display:"flex", flexDirection:"column"}}>
                            <div>
                                <div style={{top:"0", left:"0", width:"100%", position:"relative" }}>
                                    <img loading="lazy" src={data[3].img} style={{minWidth:"360px", maxWidth:"360px",minHeight:"350px", maxHeight:"350px", borderRadius: "20px", objectFit: "fill" }} />
                                    <div style={{position:"absolute", top:"0", left:"0", width:"100%", height:"100%", background:"rgba(0, 0, 0, 0.4)", pointerEvents:"none", borderRadius: "20px"}}></div>
                                    <div style={{position:"absolute", top:"77%", left:"5%", color:"white", fontFamily:"Lato", fontWeight:"900", fontSize:"22px", lineHeight:"1em", textAlign:"left"}}>{data[3].title}<br/> 
                                        <span style={{fontWeight:"400", fontSize:"15px"}}>From ${data[3].price}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{display:"flex", flexDirection:"column", justifyContent:"space-between"}}>
                            <div style={{top:"0", left:"0", width:"100%", position:"relative" }}>
                                <img loading="lazy" src={data[4].img} style={{minWidth:"360px", maxWidth:"360px",minHeight:"160px", maxHeight:"160px", borderRadius: "20px", objectFit: "fill" }} />
                                <div style={{position:"absolute", top:"0", left:"0", width:"100%", height:"100%", background:"rgba(0, 0, 0, 0.4)", pointerEvents:"none", borderRadius: "20px"}}></div>
                                    <div style={{position:"absolute", top:"56%", left:"5%", color:"white", fontFamily:"Lato", fontWeight:"900", fontSize:"22px", lineHeight:"1em", textAlign:"left"}}>{data[4].title}<br/> 
                                        <span style={{fontWeight:"400", fontSize:"15px"}}>From ${data[4].price}</span>
                                    </div>
                                </div>
                            <div style={{top:"0", left:"0", width:"100%", position:"relative" }}>
                                <img loading="lazy" src={data[5].img} style={{minWidth:"360px", maxWidth:"360px",minHeight:"160px", maxHeight:"160px", borderRadius: "20px", objectFit: "fill" }} />
                                <div style={{position:"absolute", top:"0", left:"0", width:"100%", height:"100%", background:"rgba(0, 0, 0, 0.4)", pointerEvents:"none", borderRadius: "20px"}}></div>
                                    <div style={{position:"absolute", top:"56%", left:"5%", color:"white", fontFamily:"Lato", fontWeight:"900", fontSize:"22px", lineHeight:"1em", textAlign:"left"}}>{data[5].title}<br/> 
                                        <span style={{fontWeight:"400", fontSize:"15px"}}>From ${data[5].price}</span>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomepageBackgroundColorSectionWeb