import React from "react";
import '../styles/FooterMenu.scss'
// ZZAA
// import logoPng from '../assets/Horizontal Logo.png'
import logoPng from '../assets/Horizontal L.png'

function FooterMenu(){

    return(
        <>
            <footer className="new_footer_area bg_color" style={{padding:"0 5% 0 5%"}}>
                <div className="new_footer_top font-size-20-13">
                    <div style={{position:"absolute", top:"0", left:"0", minWidth:"100%", padding:"20px 20px 10px 20px", zIndex:"9999", maxWidth:"60%"}}>
                        <img loading="lazy" className="logo-footer" src={logoPng}/>
                        {/* <div style={{fontSize:"12px"}}>Explore Beyond Boundaries, Travel Beyond Imagination.</div> */}
                        <div style={{minWidth:"100%", borderTop:"1px solid lightgrey", borderBottom:"1px solid lightgrey"}}></div>
                    </div>
                    <div className="padding-top-6-3" style={{display:"flex", alignItems:"center", color:"grey"}}>
                        <svg style={{maxWidth:"20px", marginRight:"5px"}} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M4 18L9 12M20 18L15 12M3 8L10.225 12.8166C10.8665 13.2443 11.1872 13.4582 11.5339 13.5412C11.8403 13.6147 12.1597 13.6147 12.4661 13.5412C12.8128 13.4582 13.1335 13.2443 13.775 12.8166L21 8M6.2 19H17.8C18.9201 19 19.4802 19 19.908 18.782C20.2843 18.5903 20.5903 18.2843 20.782 17.908C21 17.4802 21 16.9201 21 15.8V8.2C21 7.0799 21 6.51984 20.782 6.09202C20.5903 5.71569 20.2843 5.40973 19.908 5.21799C19.4802 5 18.9201 5 17.8 5H6.2C5.0799 5 4.51984 5 4.09202 5.21799C3.71569 5.40973 3.40973 5.71569 3.21799 6.09202C3 6.51984 3 7.07989 3 8.2V15.8C3 16.9201 3 17.4802 3.21799 17.908C3.40973 18.2843 3.71569 18.5903 4.09202 18.782C4.51984 19 5.07989 19 6.2 19Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                        {/* ZZAA */}
                        operations@welcometravels.co.za
                        {/* director@allindiatours.com */}
                    </div>
                    <div style={{display:"flex", alignItems:"center", color:"grey", paddingTop:"1%"}}>
                    <svg style={{maxWidth:"20px", marginRight:"5px"}} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M16.5562 12.9062L16.1007 13.359C16.1007 13.359 15.0181 14.4355 12.0631 11.4972C9.10812 8.55901 10.1907 7.48257 10.1907 7.48257L10.4775 7.19738C11.1841 6.49484 11.2507 5.36691 10.6342 4.54348L9.37326 2.85908C8.61028 1.83992 7.13596 1.70529 6.26145 2.57483L4.69185 4.13552C4.25823 4.56668 3.96765 5.12559 4.00289 5.74561C4.09304 7.33182 4.81071 10.7447 8.81536 14.7266C13.0621 18.9492 17.0468 19.117 18.6763 18.9651C19.1917 18.9171 19.6399 18.6546 20.0011 18.2954L21.4217 16.883C22.3806 15.9295 22.1102 14.2949 20.8833 13.628L18.9728 12.5894C18.1672 12.1515 17.1858 12.2801 16.5562 12.9062Z" fill="#1C274C"></path> </g></svg>
                        {/* ZZAA */}
                        +27837789189
                        {/* +91 73733 39933 */}
                    </div>
                    <div style={{display:"flex", alignItems:"center", color:"grey", paddingTop:"1%"}}>
                    <svg style={{maxWidth:"20px", marginRight:"5px"}} viewBox="0 0 1024 1024" fill="#000000" class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M512 1012.8c-253.6 0-511.2-54.4-511.2-158.4 0-92.8 198.4-131.2 283.2-143.2h3.2c12 0 22.4 8.8 24 20.8 0.8 6.4-0.8 12.8-4.8 17.6-4 4.8-9.6 8.8-16 9.6-176.8 25.6-242.4 72-242.4 96 0 44.8 180.8 110.4 463.2 110.4s463.2-65.6 463.2-110.4c0-24-66.4-70.4-244.8-96-6.4-0.8-12-4-16-9.6-4-4.8-5.6-11.2-4.8-17.6 1.6-12 12-20.8 24-20.8h3.2c85.6 12 285.6 50.4 285.6 143.2 0.8 103.2-256 158.4-509.6 158.4z m-16.8-169.6c-12-11.2-288.8-272.8-288.8-529.6 0-168 136.8-304.8 304.8-304.8S816 145.6 816 313.6c0 249.6-276.8 517.6-288.8 528.8l-16 16-16-15.2zM512 56.8c-141.6 0-256.8 115.2-256.8 256.8 0 200.8 196 416 256.8 477.6 61.6-63.2 257.6-282.4 257.6-477.6C768.8 172.8 653.6 56.8 512 56.8z m0 392.8c-80 0-144.8-64.8-144.8-144.8S432 160 512 160c80 0 144.8 64.8 144.8 144.8 0 80-64.8 144.8-144.8 144.8zM512 208c-53.6 0-96.8 43.2-96.8 96.8S458.4 401.6 512 401.6c53.6 0 96.8-43.2 96.8-96.8S564.8 208 512 208z" fill=""></path></g></svg>
                        {/* ZZAA */}
                        194 Titren Road, Seaview, Durban 4093
                        {/* 1 Woods Road, Chennai, India */}
                    </div>
                    <div className="container footer-social-icons">
                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                        <div
                            className="f_widget company_widget wow fadeInLeft"
                            data-wow-delay="0.2s"
                            style={{
                            visibility: "visible",
                            animationDelay: "0.2s",
                            animationName: "fadeInLeft"
                            }}
                        >
                            {/* <h3 className="f-title f_600 t_color f_size_18">Get in Touch</h3>
                            <p>Don’t miss any updates of our new templates and extensions.!</p> */}
                            {/* <form
                            action="#"
                            className="f_subscribe_two mailchimp"
                            method="post"
                            noValidate="true"
                            _lpchecked={1}
                            >
                            <input
                                type="text"
                                name="EMAIL"
                                className="form-control memail"
                                placeholder="Email"
                            />
                            <button className="btn btn_get btn_get_two" type="submit">
                                Subscribe
                            </button>
                            <p className="mchimp-errmessage" style={{ display: "none" }} />
                            <p className="mchimp-sucmessage" style={{ display: "none" }} />
                            </form> */}
                        </div>
                        </div>
                        {/* <div className="col-lg-3 col-md-6"> */}
                        {/* <div
                            className="f_widget about-widget pl_70 wow fadeInLeft"
                            data-wow-delay="0.4s"
                            style={{
                            visibility: "visible",
                            animationDelay: "0.4s",
                            animationName: "fadeInLeft"
                            }}
                        >
                            <h3 className="f-title f_600 t_color f_size_18">Download</h3>
                            <ul className="list-unstyled f_list">
                            <li>
                                <a href="#">Company</a>
                            </li>
                            <li>
                                <a href="#">Android App</a>
                            </li>
                            <li>
                                <a href="#">ios App</a>
                            </li>
                            <li>
                                <a href="#">Desktop</a>
                            </li>
                            <li>
                                <a href="#">Projects</a>
                            </li>
                            <li>
                                <a href="#">My tasks</a>
                            </li>
                            </ul>
                        </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                        <div
                            className="f_widget about-widget pl_70 wow fadeInLeft"
                            data-wow-delay="0.6s"
                            style={{
                            visibility: "visible",
                            animationDelay: "0.6s",
                            animationName: "fadeInLeft"
                            }}
                        >
                            <h3 className="f-title f_600 t_color f_size_18">Help</h3>
                            <ul className="list-unstyled f_list">
                            <li>
                                <a href="#">FAQ</a>
                            </li>
                            <li>
                                <a href="#">Term &amp; conditions</a>
                            </li>
                            <li>
                                <a href="#">Reporting</a>
                            </li>
                            <li>
                                <a href="#">Documentation</a>
                            </li>
                            <li>
                                <a href="#">Support Policy</a>
                            </li>
                            <li>
                                <a href="#">Privacy</a>
                            </li>
                            </ul>
                        </div> */}
                        {/* </div> */}
                        <div className="col-lg-3 col-md-6">
                        <div
                            className="f_widget social-widget pl_70 wow fadeInLeft"
                            data-wow-delay="0.8s"
                            style={{
                            visibility: "visible",
                            animationDelay: "0.8s",
                            animationName: "fadeInLeft"
                            }}
                        >
                            <h3 className="f-title f_600 t_color f_size_18">Follow Us!</h3>
                            <div className="f_social_icon" style={{marginTop:"-7%"}}>
                            <a href="#" className="fab fa-facebook" />
                            <a href="#" className="fab fa-twitter" />
                            <a href="#" className="fab fa-linkedin" />
                            <a href="#" className="fab fa-pinterest" />
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    {/* <div className="footer_bg">
                    <div className="footer_bg_one" />
                    <div className="footer_bg_two" />
                    </div> */}
                </div>
                {/* <div className="footer_bottom">
                    <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-sm-7">
                        <p className="mb-0 f_400">
                            © cakecounter Inc.. 2019 All rights reserved.
                        </p>
                        </div>
                        <div className="col-lg-6 col-sm-5 text-right">
                        <p>
                            Made with <i className="icon_heart" /> in{" "}
                            <a href="http://cakecounter.com" target="_blank">
                            CakeCounter
                            </a>
                        </p>
                        </div>
                    </div>
                    </div>
                </div> */}
                </footer>

        </>
    )
}

export default FooterMenu