import React, { useEffect, useState, useRef } from "react";
import '../styles/MultiStepForm3.scss'
import experts from '../assets/experts.png'
import { useParams } from 'react-router-dom';
import jsonData from '../json/Data.json';
import icelandImg from '../assets/EuropeanHolidays/iceland.webp';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaCalendarAlt } from 'react-icons/fa';
import Header from "./Header";
import emailjs from 'emailjs-com';
import baliImg from '../assets/QuickGetaway/bali.jpg';


export function useForceUpdate() {
  const [, setTick] = useState(0);
  const forceUpdate = () => {
    setTick((tick) => tick + 1);
  };
  return forceUpdate;
}

function ContactContiki ({ formData, updateFormData }){
    const forceUpdate = useForceUpdate();
    const [selectedDate, setSelectedDate] = useState(null);
    const { packageName } = useParams();
    const transformedPackageName = packageName.charAt(0).toUpperCase() + packageName.slice(1).toLowerCase();
    let filteredData = jsonData;

    useEffect(() => {
        console.log('Selected Date:', selectedDate);
        updateFormData('travel_when',selectedDate)
        forceUpdate();
      }, [selectedDate]);

    if (packageName) {
        if(packageName=='enquiry'){
            filteredData[0].title='Welcome Tours and Travels Enquiry'
            filteredData[0].img=baliImg
        }
        else{
            filteredData = jsonData.filter((packageData) =>
                packageData.package_name.includes(packageName)
            );
        }
    }

    const [isChecked1, setChecked1] = useState(false);
    const [isChecked2, setChecked2] = useState(false);
    const [isChecked3, setChecked3] = useState(false);
    const [isChecked4, setChecked4] = useState(false);
    const [isChecked5, setChecked5] = useState(false);

    useEffect(() => {
        updateFormData('package_name',packageName)
        let updatedContactBy = '';
      
        if (isChecked1) {
          updatedContactBy += 'email, ';
        }
      
        if (isChecked2) {
          updatedContactBy += 'phone, ';
        }
      
        // Remove trailing comma and space
        updatedContactBy = updatedContactBy.replace(/, $/, '');
      
        // Update formData with the new contactBy value
        console.log(updatedContactBy)
        updateFormData('contactBy', updatedContactBy);
      }, [isChecked1, isChecked2]);

    const handleCheckbox1Change = () => {
        setChecked1(!isChecked1);
    };

    const handleCheckbox2Change = () => {
        setChecked2(!isChecked2);
    };
    const handleCheckbox3Change = () => {
        setChecked3(!isChecked3);
    };
    const handleCheckbox4Change = () => {
        setChecked4(!isChecked4);
    };
    const handleCheckbox5Change = () => {
        setChecked5(!isChecked5);
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        updateFormData(name, value);
    };

    const sendEmail = (event) => {
        event.preventDefault();
        const btn = document.getElementById('button');
        btn.value = 'Sending...';

        const serviceID = 'service_04znkyr';
        const templateID = 'template_fstvbgh';
        emailjs.init('yab5q0AK_ic61HuDW');

        emailjs.send(serviceID, templateID, formData)
        .then(() => {
            btn.value = 'Send Email';
            alert('Sent!');
        }, (err) => {
            btn.value = 'Send Email';
            alert(JSON.stringify(err));
        });
    };
    
    return(
        <div style={{overflowX:"hidden"}}>
            {/* <div>
                <div style={{position:"fixed", top:"0", left:"0", minWidth:"100%", boxShadow: "rgba(0, 0, 0, 0.1) 0px -20px 13px 16px", zIndex:"999999" }}>
                </div>
                <div style={{position:"absolute", top:"0", left:"0", maxWidth:"100%", padding:"10px", zIndex:"9999"}}>
                    <a href='/'>
                        REQUEST MORE INFO
                    </a>
                    <div style={{position:"absolute", top:"10%", left:"80%", padding:"20px", zIndex:"9999", maxWidth:"50%"}}>
                        <svg style={{fill:"white"}} width="1em" height="1em" viewBox="0 0 24 24" type="button" aria-haspopup="dialog" aria-expanded="false" aria-controls="radix-0" data-state="closed" class="PJLV PJLV-ifiGNBN-css"><path fill-rule="evenodd" d="M22 20.889c0 .614-.497 1.111-1.111 1.111H3.11a1.111 1.111 0 010-2.222H20.89c.614 0 1.111.497 1.111 1.11zm-5.23-4.77a1.111 1.111 0 010-1.571L19.317 12 16.77 9.452a1.111 1.111 0 011.571-1.571l3.334 3.333a1.111 1.111 0 010 1.572l-3.334 3.333a1.111 1.111 0 01-1.571 0zM15.535 12c0 .614-.497 1.111-1.11 1.111H3.11a1.111 1.111 0 010-2.222h11.313c.614 0 1.111.497 1.111 1.111zM22 3.111c0 .614-.497 1.111-1.111 1.111H3.11a1.111 1.111 0 110-2.222H20.89C21.503 2 22 2.497 22 3.111z" clip-rule="evenodd"></path></svg>
                    </div>
                </div>
            </div> */}
            <Header dark={true}/>
            <div style={{width:"100%", height:"4px", position:"relative"}}>
                <div style={{height:"100%", position:"absolute", top:"6.8vh", left:"0", backgroundColor:"rgb(237, 239, 240)", width:"100%"}}></div>
                {/* <div style={loaderLineStyle}></div> */}
            </div>
            <div style={{margin:"10vh 4% 0 4%", display:"flex", flexDirection:"column", justifyContent:"center", alignContent:"center", minWidth:"92%",maxWidth:"100%",boxShadow: "0 1px 4px 0 rgba(63,65,68,.1)", borderRadius:"5px", paddingBottom:"4%", backgroundColor:"white"}}>
                <img loading="lazy" src={filteredData[0].img} style={{textAlign:"center", borderRadius:"5px 5px 0 0", maxHeight:"25vh", width:"100%"}}/>
                <div style={{fontFamily:"Maven Pro", fontSize:"24px", fontWeight:"700", color:"black", zIndex:"2", padding:"5% 5% 2% 5%", textTransform:"capitalize"}}>
                    {transformedPackageName}
                </div>
                <div style={{fontFamily:"Lato", fontSize:"16px", color:"black", zIndex:"2", padding:"0 5%"}}>
                    {filteredData[0].title}
                </div>
            </div> 
            <div style={{margin:"2% 7% 0 7%", maxWidth:"100%", display:"flex", flexDirection:"column",flexWrap:"wrap", justifyContent:"center", alignItems:"center"}}>
                {/* <div style={{marginTop:"5%"}}>
                    <div style={{fontWeight:"600", fontSize:"18px", alignItems:"left", color:"rgb(63, 65, 68)", fontSize:"16px", textAlign:"center"}}>We're ready to share your perfect trip with you — free of charge. To save your information and continue planning with us, please confirm your contact details. </div>
                </div> */}
                <div style={{marginTop:"5%", border:"1px solid rgb(200, 204, 207)", width:"100%", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", height:"35px", borderRadius:"5px"}}>
                <DatePicker
                    selected={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    dateFormat="dd-MM-yyyy"
                    minDate={new Date()} // Set minimum date to today
                    customInput={
                    <div className="custom-input">
                        <input
                        style={{ border: 'none', minWidth: '100%', height: '30px', outline: 'none', marginRight:"55px" }}
                        placeholder="Select Date"
                        />
                    </div>
                    }
                    calendarIcon={<FaCalendarAlt />}
                />
                </div>
                <div style={{marginTop:"5%", border:"1px solid rgb(200, 204, 207)", width:"100%", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", height:"45px", borderRadius:"5px"}}>
                    <div style={{width:"9%",display:"flex",alignContent:"center", justifyContent:"center", alignItems:"center", textAlign:"center"}}>
                        <svg style={{minWidth:"20px", maxWidth:"20px", fill:"rgb(200, 204, 207)"}} viewBox="0 0 24 24"><path d="M12 12c2.206 0 4-1.794 4-4s-1.794-4-4-4-4 1.794-4 4 1.794 4 4 4m0 1c-4.71 0-8 2.467-8 6v1h16v-1c0-3.533-3.29-6-8-6" fill-rule="evenodd"></path></svg>
                    </div>
                    <div style={{width:"87%"}}>
                        <input type="text" name="firstname" style={{border:"none", minWidth:"100%", height:"30px", border:"none"}} placeholder="First Name" onChange={handleInputChange}/>
                    </div>
                </div>
                <div style={{marginTop:"5%", border:"1px solid rgb(200, 204, 207)", width:"100%", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", height:"45px", borderRadius:"5px"}}>
                    <div style={{width:"9%",display:"flex",alignContent:"center", justifyContent:"center", alignItems:"center", textAlign:"center"}}>
                        <svg style={{minWidth:"20px", maxWidth:"20px", fill:"rgb(200, 204, 207)"}} viewBox="0 0 24 24"><path d="M12 12c2.206 0 4-1.794 4-4s-1.794-4-4-4-4 1.794-4 4 1.794 4 4 4m0 1c-4.71 0-8 2.467-8 6v1h16v-1c0-3.533-3.29-6-8-6" fill-rule="evenodd"></path></svg>
                    </div>
                    <div style={{width:"87%"}}>
                        <input type="text" name="lastname" style={{border:"none", minWidth:"100%", height:"30px", border:"none"}} placeholder="Last Name" onChange={handleInputChange}/>
                    </div>
                </div>
                <div style={{marginTop:"5%", border:"1px solid rgb(200, 204, 207)", width:"100%", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", height:"45px", borderRadius:"5px"}}>
                    <div style={{width:"9%",display:"flex",alignContent:"center", justifyContent:"center", alignItems:"center", textAlign:"center"}}>
                        <svg style={{minWidth:"20px", maxWidth:"20px", fill:"rgb(200, 204, 207)"}} viewBox="0 0 24 24"><path d="M5 5c-.88 0-1.992 1.106-1.992 2L3 16.06C3 16.953 4.12 18 5 18h13c.88 0 2-1.047 2-1.94V7c0-.894-1.12-2-2-2H5zm6.5 8L5 8.714V7l6.5 4.286L18 7v1.714L11.5 13z" fill-rule="evenodd"></path></svg>
                    </div>
                    <div style={{width:"87%"}}>
                        <input type="email" name="email" style={{border:"none", minWidth:"100%", height:"30px", border:"none"}} placeholder="Email" onChange={handleInputChange}/>
                    </div>
                </div>
                <div style={{marginTop:"5%", border:"1px solid rgb(200, 204, 207)", width:"100%", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", height:"45px", borderRadius:"5px"}}>
                    <div style={{width:"9%",display:"flex",alignContent:"center", justifyContent:"center", alignItems:"center", textAlign:"center"}}>
                        <svg style={{minWidth:"20px", maxWidth:"20px", fill:"rgb(200, 204, 207)"}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="phone"><path d="M27.308,20.649l-2.2-2.2a3.521,3.521,0,0,0-4.938-.021,2.152,2.152,0,0,1-2.729.267A15.026,15.026,0,0,1,13.3,14.562a2.181,2.181,0,0,1,.284-2.739A3.521,3.521,0,0,0,13.553,6.9l-2.2-2.2a3.514,3.514,0,0,0-4.961,0l-.633.634c-3.3,3.3-3.053,10.238,3.813,17.1,4.14,4.141,8.307,5.875,11.686,5.875a7.5,7.5,0,0,0,5.418-2.061l.634-.634A3.513,3.513,0,0,0,27.308,20.649ZM25.894,24.2l-.634.634c-2.6,2.6-8.339,2.125-14.276-3.813S4.571,9.34,7.171,6.74L7.8,6.107a1.511,1.511,0,0,1,2.133,0l2.2,2.2a1.511,1.511,0,0,1,.021,2.11,4.181,4.181,0,0,0-.531,5.239,17.01,17.01,0,0,0,4.713,4.706,4.179,4.179,0,0,0,5.231-.517,1.512,1.512,0,0,1,2.118.013l2.2,2.2A1.51,1.51,0,0,1,25.894,24.2Z"></path></svg>
                    </div>
                    <div style={{width:"87%"}}>
                        <input type="phone" name="phone" style={{border:"none", minWidth:"100%", height:"30px", border:"none"}} placeholder="Phone number" onChange={handleInputChange}/>
                    </div>
                </div>
                <div style={{position:"relative", display:"flex", flexDirection:"column", minWidth:"100%", padding:"5% 0", color:"black", fontFamily:"Lato", fontWeight:"400"}}>
                    <div style={{fontFamily:"Maven Pro", fontWeight:"700", fontSize:"18px"}}>
                        How would like us to contact you?
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', minHeight:"6vh", verticalAlign:"center", maxHeight:"6vh" }}>
                        <input
                        id="after-position-relative"
                        type="checkbox"
                        checked={isChecked1}
                        onChange={handleCheckbox1Change}
                        style={{position:"relative"}}
                        />
                        <div style={{ marginLeft: '20px', display:"flex", justifyContent:"center", alignItems:"center", minHeight:"6vh", maxHeight:"6vh" }}>By Email</div>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', minHeight:"6vh"  }}>
                        <input
                        id="after-position-relative"
                        type="checkbox"
                        checked={isChecked2}
                        onChange={handleCheckbox2Change}
                        tyle={{position:"relative"}}
                        />
                        <span style={{ marginLeft: '20px' }}>By Phone</span>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', paddingTop:"5%", minHeight:"6vh"  }}>
                        <input
                        id="after-position-relative"
                        type="checkbox"
                        checked={isChecked3}
                        onChange={handleCheckbox3Change}
                        tyle={{position:"relative"}}
                        />
                        <span style={{ marginLeft: '20px' }}>Keep me updated on the latest Welcome news, deals and latest trips</span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', minHeight:"6vh" }}>
                        <input
                        id="after-position-relative"
                        type="checkbox"
                        checked={isChecked4}
                        onChange={handleCheckbox4Change}
                        tyle={{position:"relative"}}
                        />
                        <span style={{ marginLeft: '20px' }}>I am a Welcome past traveller</span>
                    </div>
                </div>
            </div>
            <div>
                <a href="#" style={{color:"white", textDecoration:"none", fontFamily:"Montserrat", padding:"0 5% 0 0"}}>
                    <button id='button' onClick={sendEmail} style={{width:"90%", background:"#12765e", height:"6vh", borderRadius:"20px" , margin:"0 5%", display:"flex", justifyContent:"center", alignContent:"center", alignItems:"center", color:"white", fontSize:"17px", letterSpacing:"4px", boxShadow:"0 0 8px rgba(117, 117, 117, 0.5)", borderRadius:"25px"}}>
                        SUBMIT
                    </button>
                </a>
            </div>
        </div>
    )
}

export default ContactContiki