import React from 'react'
import '../styles/Homepage.scss'

function PackageIncludes({packageData}) {
    return(
        <>
            <div className='package-includes' style={{backgroundColor:"rgb(247,247,247)", display:"flex", justifyContent:"left", maxWidth:"100%", padding:"5% 0%", borderRadius:"5px"}}>
                <div style={{padding:"0 4%", minWidth:"100%"}}>
                    <div style={{fontFamily:"Montserrat", letterSpacing:"6px", textAlign:"left", color:"black", fontSize:"13px"}}>INCLUDES</div>
                    <div style={{paddingTop:"10px", minWidth:"100%"}}>
                        <div style={{float:"left", maxWidth:"45%", minWidth:"45%"}}>
                            <div style={{display:"flex", flexDirection:"column"}}>
                                <div style={{display:"flex", flexDirection:"row", paddingTop:"8%"}}>
                                    <svg style={{minWidth:"15px",maxWidth:"17px", height:"20px", fill:"black"}} xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 14.92174 15.6849" class="injected-svg icon icon-with-description__icon" data-src="/assets/shared/icons/booking-sv.svg"><path d="M10.851,1.21925V.18756H9.811V1.21925H5.41936V.18756h-1.04V1.21925H.15476V15.87246H15.0765V1.21925ZM5.36356,12.73305H3.13945V10.50894H5.36356ZM5.36394,9.507H3.139V7.282H5.36394Zm3.36368,3.22608H6.50351V10.50894H8.72762ZM8.728,9.507H6.50313V7.282H8.728Zm3.36368,3.22608H9.86757V10.50894h2.22411ZM12.09206,9.507H9.86719V7.282h2.22487ZM14.03651,4.5192H1.19477V2.25925H4.37936V3.291h1.04V2.25925H9.811V3.29648h1.04V2.25925h3.18552Z" transform="translate(-0.15476 -0.18756)"></path></svg>
                                    <div style={{fontSize:"15px", paddingLeft:"10px", fontWeight:"bolder"}}>Days</div>
                                </div>
                                <div style={{fontSize:"13px", paddingTop:"5%", textDecoration:"underline"}}>
                                    {packageData.total_nights} Nights | {packageData.total_days} Days
                                </div>
                            </div>
                            <div style={{display:"flex", flexDirection:"column"}}>
                                <div style={{display:"flex", flexDirection:"row", paddingTop:"12%"}}>
                                    <svg style={{minWidth:"15px",maxWidth:"17px", height:"20px", fill:"black"}} xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 30.79791 27.8471" class="injected-svg icon icon-with-description__icon" data-src="/assets/shared/icons/ch-included-meals-spoon-fork-filled.svg" ><path d="M19.66906,17.43278l.81656-.79957a4.906,4.906,0,0,0,5.87064-.90689.93522.93522,0,0,0,.229-.161L32.616,9.53371l-1.24508-1.25L25.503,14.1415l-.956-.95508,5.8655-5.86617-1.06284-1.0634-5.85678,5.87433-.89068-.90734,5.87019-5.857L27.38009,4.28946,21.532,10.1724l-.97755-.975L26.43329,3.3469,25.165,2.07645l-6.03878,6.037a.75207.75207,0,0,0-.13416.19139c-1.67426,1.88891-2.00249,3.97637-.93283,5.90284l-.81477.80136Z" transform="translate(-1.81804 -2.07645)"></path><path d="M5.39491,26.8809a1.71479,1.71479,0,1,0,2.42464,2.42553L15.0398,22.0996l.16188-.16277-2.42553-2.42375Z" transform="translate(-1.81804 -2.07645)"></path><path d="M13.74744,13.30437c.83176-2.18584.01162-5.01384-2.074-7.09772A8.0522,8.0522,0,0,0,7.19975,3.858a4.74626,4.74626,0,0,0-4.12125,1.202c-2.05526,2.05526-1.54279,5.91178,1.14121,8.59668,2.08746,2.08925,4.919,2.91028,7.10309,2.07673L25.01292,29.42091a1.71479,1.71479,0,1,0,2.42463-2.42553Z" transform="translate(-1.81804 -2.07645)"></path></svg>
                                    <div style={{fontSize:"15px", paddingLeft:"10px", fontWeight:"bolder"}}>Meals</div>
                                </div>
                                <div style={{fontSize:"13px", paddingTop:"5%"}}>
                                    {packageData.meals_included}
                                </div>
                            </div>
                            {/* <div style={{display:"flex", flexDirection:"column"}}>
                                <div style={{display:"flex", flexDirection:"row", paddingTop:"12%"}}>
                                <svg style={{minWidth:"15px",maxWidth:"17px", height:"20px", fill:"black"}} xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 31.2 23.74362" class="injected-svg icon icon-with-description__icon" data-src="/assets/shared/icons/ch-group-size-two-people.svg"><path d="M16.02479,19.08532a8.82378,8.82378,0,0,0-5.31195-1.75966h-.52258A8.9104,8.9104,0,0,0,1.2905,26.22543v1.46708h18.323V26.22543c0-.18688-.00677-.37544-.01861-.55978A8.88538,8.88538,0,0,0,16.02479,19.08532Z" transform="translate(-1.2905 -3.9489)"></path><path d="M10.4507,16.63294a5.86716,5.86716,0,0,0,5.69755-6.01209,5.86792,5.86792,0,0,0-5.69755-6.013,5.86763,5.86763,0,0,0-5.69584,6.013A5.86687,5.86687,0,0,0,10.4507,16.63294Z" transform="translate(-1.2905 -3.9489)"></path><path d="M24.10062,14.818h.00169a5.00661,5.00661,0,0,0,3.68252-1.66072,5.56235,5.56235,0,0,0,1.44679-3.817A5.335,5.335,0,0,0,24.01437,3.9489a5.01112,5.01112,0,0,0-3.68251,1.659,5.63827,5.63827,0,0,0,.06172,7.61025A5.06837,5.06837,0,0,0,24.10062,14.818ZM21.5183,12.13638a4.05781,4.05781,0,0,1-.04419-5.46407,3.49116,3.49116,0,0,1,2.581-1.15676A3.764,3.764,0,0,1,27.678,9.35374a3.99116,3.99116,0,0,1-1.03546,2.74087,3.45793,3.45793,0,0,1-5.12427.04177Z" transform="translate(-1.2905 -3.9489)"></path><path d="M23.95687,15.32876h-.43463A8.51528,8.51528,0,0,0,17.80187,17.534l-.608.54794.64856.4972a10.04555,10.04555,0,0,1,.93014.81683,9.41206,9.41206,0,0,1,2.749,6.17276l.03213.64772H32.4905V23.86238A8.54356,8.54356,0,0,0,23.95687,15.32876Zm-1.00719,9.32061a10.0736,10.0736,0,0,0-.74306-2.84049,11.32872,11.32872,0,0,0-2.4709-3.37683c-.13106-.13022.02163-.12879-.11012-.24767a6.84323,6.84323,0,0,1,3.982-1.2885h.4129a6.81961,6.81961,0,0,1,6.812,6.812v.94147Z" transform="translate(-1.2905 -3.9489)"></path></svg>
                                    <div style={{fontSize:"15px", paddingLeft:"10px", fontWeight:"bolder"}}>Group Size</div>
                                </div>
                                <div style={{fontSize:"13px", paddingTop:"5%"}}>
                                    Average 24 people
                                </div>
                            </div> */}
                        </div>
                        <div style={{float:"right", maxWidth:"45%", minWidth:"45%"}}>
                            <div style={{display:"flex", flexDirection:"column"}}>
                                <div style={{display:"flex", flexDirection:"row", paddingTop:"8%"}}>
                                <svg style={{minWidth:"15px",maxWidth:"17px", height:"20px", fill:"black"}} xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 30.20164 29" class="injected-svg icon icon-with-description__icon" data-src="/assets/shared/icons/ch-accomodation-hotel-filled.svg"><path d="M11.53591,14.78324l-.05169-.04632-9.67228,8.7049-.14966.1354H5.00991v6.84224h5.04124v-5.131h2.8426v5.131h5.06661V23.57722h3.34585Z" transform="translate(-1.66228 -1.41946)"></path><path d="M15.37877,1.41946v14.9336l8.546,8.2733H19.54927l.0637,5.7931,12.17256-.01158h.07839V1.41946Zm5.21006,15.88821H18.23362V15.22116h2.37167Zm0-3.72094H18.23362v-2.087h2.37167Zm0-3.72135H18.23362V7.77888h2.37167Zm0-3.72072H18.23362V4.05816h2.37167Zm4.19983,11.163H22.435V15.22116h2.37167Zm0-3.72094H22.435v-2.087h2.37167ZM22.43648,9.85752,22.444,7.78028l2.36127.00659-.02554,2.07724Zm2.35218-3.71286H22.435V4.05816h2.37167Zm4.23186,11.16305h-2.3552V15.2212H29.037Zm0-3.721h-2.3552v-2.087H29.037Zm0-3.72137h-2.3552V7.77886H29.037Zm0-3.72075h-2.3552V4.05748H29.037Z" transform="translate(-1.66228 -1.41946)"></path></svg>
                                    <div style={{fontSize:"15px", paddingLeft:"10px", fontWeight:"bolder"}}>Accommodation</div>
                                </div>
                                <div style={{fontSize:"13px", paddingTop:"5%"}}>
                                    {packageData.total_nights_accomodation_in_hotel} nights in Hotels
                                </div>
                            </div>
                            <div style={{display:"flex", flexDirection:"column"}}>
                                <div style={{display:"flex", flexDirection:"row", paddingTop:"8%"}}>
                                <svg style={{minWidth:"15px",maxWidth:"17px", height:"20px", fill:"black"}} xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 31 25.7269" class="injected-svg icon icon-with-description__icon" data-src="/assets/shared/icons/ch-transport-arrow-dots-filled.svg"><path d="M4.98664,19.41722a3.03836,3.03836,0,1,1,3.038-3.038A3.04177,3.04177,0,0,1,4.98664,19.41722Z" transform="translate(-1.9486 -3.51539)"></path><path d="M29.91056,19.41722a3.03836,3.03836,0,1,1,3.038-3.038A3.04177,3.04177,0,0,1,29.91056,19.41722Z" transform="translate(-1.9486 -3.51539)"></path><path d="M25.27579,16.376a4.95635,4.95635,0,0,1,4.09172-4.5188A12.80669,12.80669,0,0,0,5.36345,11.851c2.14828.39152,4.237,2.34026,4.237,4.60039,0,2.25934-2.08866,4.06375-4.23694,4.45528a12.80655,12.80655,0,0,0,24.00386-.00623A4.96318,4.96318,0,0,1,25.27579,16.376Zm-6.2923,4.28943-1.326-1.326,2.15116-2.15195h-8.126v-1.875h8.126l-2.15116-2.15194,1.326-1.326,4.41544,4.41623Z" transform="translate(-1.9486 -3.51539)"></path></svg>
                                    <div style={{fontSize:"15px", paddingLeft:"10px", fontWeight:"bolder"}}>Transport</div>
                                </div>
                                <div style={{fontSize:"13px", paddingTop:"5%"}}>
                                    {packageData.transport}
                                </div>
                            </div>
                            {/* <div style={{display:"flex", flexDirection:"column"}}>
                                <div style={{display:"flex", flexDirection:"row", paddingTop:"8%"}}>
                                <svg style={{minWidth:"15px",maxWidth:"17px", height:"20px", fill:"black"}} xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 28.15499 29.47481" class="injected-svg icon icon-with-description__icon" data-src="/assets/shared/icons/ch-team-filled.svg"><path d="M16.99991,12.14271a5.44006,5.44006,0,1,0-5.44006-5.44006A5.44008,5.44008,0,0,0,16.99991,12.14271Zm0,1.20881c-4.95642,0-8.704,1.60193-11.14,4.751C2.8766,21.95991,2.715,27.22077,3.043,30.73741H7.397c-.15414-8.00137,3.43887-9.89253,3.43887-9.89253v9.89253H23.11071V20.84488s3.59312,1.89116,3.43887,9.89253h4.4072c.32823-3.51664.16667-8.7775-2.81688-12.6349C25.70394,14.95345,21.95633,13.35152,16.99991,13.35152Z" transform="translate(-2.92248 -1.26259)"></path></svg>
                                    <div style={{fontSize:"15px", paddingLeft:"10px", fontWeight:"bolder"}}>Team</div>
                                </div>
                                <div style={{fontSize:"13px", paddingTop:"5%"}}>
                                    Expert Trip Manager + Local Guides
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PackageIncludes