import React, { useEffect, useState } from "react";
import '../styles/FAQ.scss'
import $  from "jquery";

function FAQ() {
    useEffect(() => {
        $(".open").click(function () {
            var container = $(this).parents(".topic");
            var answer = container.find(".answer");
            var trigger = container.find(".faq-t");

            answer.slideToggle(300); // Adjust the duration as needed
            container.toggleClass("expanded");

            trigger.toggleClass("faq-o");
        });
    });

    return(
        <>
            <section class="faq-section" style={{fontFamily:"Lato"}}>
                <div class="container">
                <div class="row">
                    <div class="col-md-6 offset-md-3">

                        <div class="faq-title text-center pb-3">
                            <h2>FAQ</h2>
                        </div>
                    </div>
                    <div class="col-md-6 offset-md-3">
                        <div class="faq" id="accordion">
                            <div class="card">
                                <div class="card-header" id="faqHeading-1">
                                    <div class="mb-0">
                                        <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-1" data-aria-expanded="true" data-aria-controls="faqCollapse-1">
                                            What does Welcome Tours include?
                                            {/* <svg style={{minWidth:"40px"}} width="25" height="25" viewBox="0 0 25 25" fill="none" data-src="/assets/shared/icons/add.svg" ><path fill-rule="evenodd" clip-rule="evenodd" d="M24.2598 12.4431C24.2598 11.4963 23.4923 10.7288 22.5455 10.7288H13.9741V2.1574C13.9741 1.21063 13.2065 0.443115 12.2598 0.443115C11.313 0.443115 10.5455 1.21063 10.5455 2.1574V10.7288H1.97405C1.02728 10.7288 0.259766 11.4963 0.259766 12.4431C0.259766 13.3899 1.02728 14.1574 1.97405 14.1574H10.5455V22.7288C10.5455 23.6756 11.313 24.4431 12.2598 24.4431C13.2065 24.4431 13.9741 23.6756 13.9741 22.7288V14.1574H22.5455C23.4923 14.1574 24.2598 13.3899 24.2598 12.4431Z"></path></svg> */}
                                        </h5>
                                    </div>
                                </div>
                                <div id="faqCollapse-1" class="collapse" aria-labelledby="faqHeading-1" data-parent="#accordion">
                                    <div class="card-body">
                                        <p>Daily Breakfast and Transportation - Air-conditioned independent vehicle with driver is provided for your family (group) as per the programme detailed above for airport pickup and drop, sightseeing, shopping and traveling from one city to another city, including fuel, driver allowances, parking fee, permit fee, toll fee and taxes.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="faqHeading-2">
                                    <div class="mb-0">
                                        <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-2" data-aria-expanded="false" data-aria-controls="faqCollapse-2">
                                            {/* <span class="badge">2</span> */}
                                            What does Welcome Tours exclude?
                                        </h5>
                                    </div>
                                </div>
                                <div id="faqCollapse-2" class="collapse" aria-labelledby="faqHeading-2" data-parent="#accordion">
                                    <div class="card-body">
                                        <p>Lunch and dinner</p>
                                        <p>Entrance ticket at places of interest</p>
                                        <p>Guide service</p>
                                        <p>Train ticket and Domestic Air ticket</p>
                                        <p>International air ticket</p>
                                        <p>All personal expenses</p>
                                        <p>All other expenses not mentioned in the price includes list</p>
                                        <p>Gala dinner on special occasions - Christmas and new year etc</p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="faqHeading-3">
                                    <div class="mb-0">
                                        <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-3" data-aria-expanded="false" data-aria-controls="faqCollapse-3">
                                            {/* <span class="badge">3</span> */}
                                            Why do we use it?
                                        </h5>
                                    </div>
                                </div>
                                <div id="faqCollapse-3" class="collapse" aria-labelledby="faqHeading-3" data-parent="#accordion">
                                    <div class="card-body">
                                        <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="faqHeading-4">
                                    <div class="mb-0">
                                        <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-4" data-aria-expanded="false" data-aria-controls="faqCollapse-4">
                                            {/* <span class="badge">4</span>  */}
                                            Where can I get some?
                                        </h5>
                                    </div>
                                </div>
                                <div id="faqCollapse-4" class="collapse" aria-labelledby="faqHeading-4" data-parent="#accordion">
                                    <div class="card-body">
                                        <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="faqHeading-5">
                                    <div class="mb-0">
                                        <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-5" data-aria-expanded="false" data-aria-controls="faqCollapse-5">
                                            {/* <span class="badge">5</span>  */}
                                            What is Lorem Ipsum?
                                        </h5>
                                    </div>
                                </div>
                                <div id="faqCollapse-5" class="collapse" aria-labelledby="faqHeading-5" data-parent="#accordion">
                                    <div class="card-body">
                                        <p> It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing</p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="faqHeading-6">
                                    <div class="mb-0">
                                        <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-6" data-aria-expanded="false" data-aria-controls="faqCollapse-6">
                                            {/* <span class="badge">6</span>  */}
                                            Where does it come from?
                                        </h5>
                                    </div>
                                </div>
                                <div id="faqCollapse-6" class="collapse" aria-labelledby="faqHeading-6" data-parent="#accordion">
                                    <div class="card-body">
                                        <p>The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="faqHeading-7">
                                    <div class="mb-0">
                                        <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-7" data-aria-expanded="false" data-aria-controls="faqCollapse-7">
                                            {/* <span class="badge">7</span>  */}
                                            Why do we use it?
                                        </h5>
                                    </div>
                                </div>
                                <div id="faqCollapse-7" class="collapse" aria-labelledby="faqHeading-7" data-parent="#accordion">
                                    <div class="card-body">
                                        <p>Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                </section>
        </>
    )
}

export default FAQ