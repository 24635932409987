import React from "react";
import goaImg from '../assets/Domestic/goa.webp';
import pondiImg from '../assets/Domestic/pondi.webp';
import himachal01Img from '../assets/Domestic/himachal 01.webp';
import rajasthanImg from '../assets/Domestic/rajasthan.webp';
import himachal02Img from '../assets/Domestic/himachal 02.webp';
import arunachalImg from '../assets/Domestic/arunachal.webp';
import himachalImg from '../assets/Domestic/himachal.webp';
import tamilnadu01Img from '../assets/Domestic/tamilnadu 01.webp';
import assamImg from '../assets/Domestic/assam.webp';
import jammuImg from '../assets/Domestic/jammu.webp';
import tamilnaduImg from '../assets/Domestic/tamilnadu.webp';
import bengalImg from '../assets/Domestic/bengal.webp';
import keralaImg from '../assets/Domestic/kerala.webp';
import telanganaImg from '../assets/Domestic/telangana.webp';
import delhiImg from '../assets/Domestic/delhi.webp';
import nagalandImg from '../assets/Domestic/nagaland.webp';
import uttarakhandImg from '../assets/Domestic/uttarakhand.webp';


function TrustTheExperts (){
    return(
        <>
        {/* <div style={{maxWidth:"90%", backgroundColor:"white", margin:"0 5%"}}>
             <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", padding:"10% 0"}}>
                <div style={{fontFamily:"Montserrat", letterSpacing:"2px", fontWeight:"200"}}>
                    TRUST THE EXPERTS
                </div>
                <div style={{fontFamily:"PlayFair Display", fontSize:"40px", textDecoration:"bolder"}}>
                    Customers Love Us
                </div>
                <div>
                    <div style={{width: "80%",whiteSpace: "nowrap",overflowX: "hidden", display:"flex", flexDirection:"row"}}>
                        <div style={{ display: "inline-block"}}>
                            <img loading="lazy" src={lakshadweepImg} style={{objectFit:"cover", minWidth:"300px",marginLeft: "20px", maxWidth:"300px", minHeight:"150px", maxHeight:"150px"}} />
                            <div>
                                
                            </div>
                        </div>
                        <div>
                            <img loading="lazy" src={lakshadweepImg} style={{objectFit:"cover", minWidth:"300px",marginLeft: "20px", maxWidth:"300px", minHeight:"150px", maxHeight:"150px"}} />
                        </div><div>
                            <img loading="lazy" src={lakshadweepImg} style={{objectFit:"cover", minWidth:"300px",marginLeft: "20px", maxWidth:"300px", minHeight:"150px", maxHeight:"150px"}} />
                        </div>
                            
                    </div>
                </div>
            </div> 
        </div> */}
        </>
    )
}

export default TrustTheExperts