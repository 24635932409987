import React, { useEffect } from "react";
import '../styles/CarasoulCards.scss'
import '../styles/Homepage.scss'
import 'swiper/swiper-bundle.css';
import Swiper from 'swiper/bundle';
import caraousel1 from '../assets/caraousel1.png'
import caraousel2 from '../assets/caraousel2.png'
import caraousel3 from '../assets/caraousel3.png'

function CarasoulCards (){
    useEffect(()=>{
        var swiper = new Swiper(".mySwiper", {
            spaceBetween: 30,
            centeredSlides: true,
            loop: true,
            autoplay: {
            delay: 6000,
            disableOnInteraction: false,
            },
            pagination: {
            el: ".swiper-pagination",
            clickable: true,
    },
  });
  
    })

    return(
        <div className="carasoul-head" style={{background:"white", boxShadow: "0 1px 4px 0 rgba(63,65,68,.3)", marginBottom:"5%"}}>
            <div className="flex-row-column">
                <h2 className="font-size-90-20 your-dream-3steps" style={{textAlign:"center", color:"black", textTransform:"capitalize",maxWidth:"100%"}}>
                    your dream travel in 3 steps
                </h2>
                <div class="swiper mySwiper font-size-24-18" style={{maxWidth:"90%", minHeight:"51vh"}}>
                    <div class="swiper-wrapper" style={{paddingBottom:"10%"}}>
                    <div class="swiper-slide" style={{textAlign:"center"}}>
                        
                        <img loading="lazy" src={caraousel1} className="carasoul-img"/>
                        <br/>
                        <div style={{paddingTop:"10%"}}>
                            <b>1. You describe</b>
                        </div>
                        <div className="carasoul-description">
                            1. You describe Adventure or beach? Apartment or resort? Let us know your travel preferences, quickly and easily online
                        </div>
                    </div>
                    <div class="swiper-slide" style={{textAlign:"center"}}>
                        
                        <img loading="lazy" src={caraousel2} className="carasoul-img"/>
                        <br/>
                        <div style={{paddingTop:"10%"}}>
                            <b>2. We organize</b>
                        </div>
                        <div className="carasoul-description">
                            Based on your preferences and wishes, our Tourlane experts organize a tailor-made trip for you over the phone.
                        </div>
                    </div>
                    <div class="swiper-slide" style={{textAlign:"center"}}>
                        
                        <img loading="lazy" src={caraousel3} className="carasoul-img"/>
                        <br/>
                        <div style={{paddingTop:"10%"}}>
                            <b>3. You travel!</b>
                        </div>
                        <div className="carasoul-description">
                            Happy with our offer? Pack your suitcase as your dream travel tour is about to start!
                        </div>
                    </div>
                    
                    </div>
                    {/* <div class="swiper-pagination" ></div> */}
                </div>
            </div>

            {/* <ul class="particles">
            <span style="--i: 11"></span>
            <span style="--i: 12"></span>
            <span style="--i: 13"></span>
            <span style="--i: 14"></span>
            <span style="--i: 15"></span>
            <span style="--i: 16"></span>
            <span style="--i: 17"></span>
            <span style="--i: 18"></span>
            <span style="--i: 19"></span>
            <span style="--i: 20"></span>
            <span style="--i: 21"></span>
            <span style="--i: 11"></span>
            <span style="--i: 12"></span>
            <span style="--i: 13"></span>
            <span style="--i: 14"></span>
            <span style="--i: 15"></span>
            <span style="--i: 16"></span>
            <span style="--i: 17"></span>
            <span style="--i: 18"></span>
            <span style="--i: 19"></span>
            <span style="--i: 20"></span>
            <span style="--i: 21"></span>
            <span style="--i: 11"></span>
            <span style="--i: 12"></span>
            <span style="--i: 13"></span>
            <span style="--i: 14"></span>
            <span style="--i: 15"></span>
            <span style="--i: 16"></span>
            <span style="--i: 17"></span>
            <span style="--i: 18"></span>
            <span style="--i: 19"></span>
            <span style="--i: 20"></span>
            <span style="--i: 21"></span>
            </ul> */}
        </div>
    )
}

export default CarasoulCards