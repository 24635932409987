import React, { createContext, useContext, useState } from 'react';

const FormContext = createContext();

export const FormProvider = ({ children }) => {
  const [progress, setProgress] = useState(10);

  const updateProgress = (newProgress) => {
    setProgress(newProgress);
  };

  return (
    <FormContext.Provider value={{ progress, updateProgress }}>
      {children}
    </FormContext.Provider>
  );
};

export const useFormContext = () => {
  return useContext(FormContext);
};
