import {React, useEffect, useState} from 'react'
import PackageIncludes from './PackageIncludes'
import Skeleton from './Skeleton';
import '../styles/Homepage.scss'

function SearchCard({data}) {
    const [loading, setLoading] = useState(true);
    const loadImage = () => {
        const img = new Image();
        img.src = process.env.PUBLIC_URL + data.img;
        img.onload = () => {
          console.log('Image is loaded');
          setLoading(false);
        };
      };

      useEffect(() => {
        console.log('Component is mounted');
        setLoading(true);
        loadImage();
      }, [process.env.PUBLIC_URL + data.img]);
    useEffect(() => {
        console.log('ImageUrl changed');
        setLoading(true);
        loadImage();
    }, [process.env.PUBLIC_URL + data.img]);

    return(
        <>
            <div className='search-card' style={{fontFamily:"Lato"}}>
                {/* <div style={{position:"relative",width:"100%", height:"300px",overflow:"hidden"}}> */}
                <div className='flex-row-column'>
                    <div className='width-100-50'>
                        {loading ? <Skeleton /> : <img className='search-card-img' loading="lazy" src={process.env.PUBLIC_URL + data.img}/>}
                    </div>
                    {/* </div> */}
                    <div className='margin-5-0 width-50-100'>
                        <div style={{marginLeft:"5%", paddingBottom:"20px", minWidth:"90%", maxWidth:"90%", alignItems:"center", paddingBottom:"20px" , borderBottom:"1px dashed rgb(195, 195, 195)"}}>
                            <div style={{paddingTop:"20px", fontWeight:"700", fontSize:"24px"}}>
                                {data.package_name}
                            </div>
                            <div style={{paddingTop:"20px",display:"flex" ,alignItems:"center"}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-bar-right" viewBox="0 0 16 16"> <path fill-rule="evenodd" d="M6 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L12.293 7.5H6.5A.5.5 0 0 0 6 8zm-2.5 7a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5z"/> </svg>
                                <div style={{paddingLeft:"10px"}}>
                                    {data.total_days} Nights {data.total_nights} Days
                                </div>
                            </div>
                            <div style={{display:"flex" ,alignItems:"center"}}>
                                {/* <div style={{display:"flex" ,alignItems:"center"}}>
                                    <svg width="24" height="1em" viewBox="0 0 24 24" fill="#00b277"><path fill-rule="evenodd" d="M19.774 5.367a1 1 0 01-.14 1.407c-2.657 2.173-4.848 4.846-6.383 6.995a44.211 44.211 0 00-1.764 2.665 31.758 31.758 0 00-.572.976l-.027.05-.006.012-.002.002a1 1 0 01-1.48.326l-4-3a1 1 0 011.2-1.6l3.1 2.325.079-.131a46.181 46.181 0 011.845-2.788c1.59-2.226 3.899-5.053 6.743-7.38a1 1 0 011.407.14z" clip-rule="evenodd"></path></svg>
                                    Hotels
                                </div>
                                <div style={{display:"flex" ,alignItems:"center", paddingLeft:"20px"}}>
                                    <svg width="24" height="1em" viewBox="0 0 24 24" fill="#00b277"><path fill-rule="evenodd" d="M19.774 5.367a1 1 0 01-.14 1.407c-2.657 2.173-4.848 4.846-6.383 6.995a44.211 44.211 0 00-1.764 2.665 31.758 31.758 0 00-.572.976l-.027.05-.006.012-.002.002a1 1 0 01-1.48.326l-4-3a1 1 0 011.2-1.6l3.1 2.325.079-.131a46.181 46.181 0 011.845-2.788c1.59-2.226 3.899-5.053 6.743-7.38a1 1 0 011.407.14z" clip-rule="evenodd"></path></svg>
                                    Flights
                                </div>
                                <div style={{display:"flex" ,alignItems:"center", paddingLeft:"20px"}}>
                                    <svg width="24" height="1em" viewBox="0 0 24 24" fill="#00b277"><path fill-rule="evenodd" d="M19.774 5.367a1 1 0 01-.14 1.407c-2.657 2.173-4.848 4.846-6.383 6.995a44.211 44.211 0 00-1.764 2.665 31.758 31.758 0 00-.572.976l-.027.05-.006.012-.002.002a1 1 0 01-1.48.326l-4-3a1 1 0 011.2-1.6l3.1 2.325.079-.131a46.181 46.181 0 011.845-2.788c1.59-2.226 3.899-5.053 6.743-7.38a1 1 0 011.407.14z" clip-rule="evenodd"></path></svg>
                                    Transfers
                                </div> */}
                                <PackageIncludes packageData={data}/>
                            </div>
                        </div>
                        <div style={{display:'flex', flexDirection:"row", margin:"5% 5%", justifyContent:"center", placeContent:"space-between"}}>
                            <div style={{display:'flex', flexDirection:"column"}}>
                                <div style={{fontSize:"14px", fontWeight:"bold"}}>
                                    Starts from
                                </div>
                                <div>
                                    {/* ZZAA */}
                                    {/* ₹ {data["3star_price"]["2_pax_price"]} <span>/person</span> */}
                                    $ {Math.round(data["3star_price"]["2_pax_price"]/83)} <span>/person</span>

                                </div>
                            </div>
                            <div>
                                <a href={`/package/${data.package_name}`}>
                                    <div style={{background:"#12765e", color:"white", padding:"10px 20px", fontSize:"14px", borderRadius:"10px"}}>
                                        View&nbsp;Tour
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SearchCard