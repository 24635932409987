import React from "react";

function HomepageVacationForTravellers({title, description, data}){
    return(
        <div className="travellers-border" style={{backgroundColor:"#3647ac", padding:"5% 0 10px 0", lineHeight:"12px"}}>
            <h2 style={{textAlign:"center", color:"white", fontWeight:"700"}}>
                {title}
            </h2>
            <p style={{textAlign:"center", color:"#24b774", fontFamily:"Dancing Script", fontSize:"28px"}}>{description}</p>
            <div style={{display:"flex", justifyContent:"center", alignContent:"center"}}>
                <div className="bg-img-width-travller" style={{ whiteSpace: "normal", textAlign: "center", display: "flex", justifyContent: "space-around", padding: "20px" }}>
                    {data.map((data, index) => (
                        <div key={index} style={{ flex: "1 0 30%", margin: "10px" }}>
                        <div className="travellers-img-home" style={{ position: "relative" }}>
                            <a href={`/search/${data.title}`}>
                            <img loading="lazy" src={data.img} className="img-fluid bg-img travellers-img-home" style={{ borderRadius: "20px", objectFit: "cover", display: "block", margin: "0 10px" }} />
                            <div style={{ display: "flex", textAlign: "center", justifyContent: "center", alignContent: "center", position: "absolute", top: "90%", left: "50%", fontSize: "12px", wordWrap: "normal", transform: "translate(-50%, -50%)", background: "#24b774", width: "100%", margin: "0 10px", height: "20%", borderRadius: "0 0 20px 20px", display: "flex", alignItems: "center", justifyContent: "center", color: "white", textTransform: "capitalize" }}>{data.title}</div>
                            </a>
                        </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default HomepageVacationForTravellers