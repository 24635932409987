import React, { useEffect, useState } from "react";
import Header from "./Header";
import '../styles/MultiStepForm3.scss'
import { useFormContext } from './FormContext';
import Includes from "./Includes";

function ContactPrice ({ formData, updateFormData }){
    const { progress, updateProgress } = useFormContext();

    useEffect(() => {
        const progressInterval = setInterval(() => {
        updateProgress((prevProgress) => Math.min(prevProgress + 10, 100));
        }, 1000);

        return () => clearInterval(progressInterval);
    }, [updateProgress]);

    const loaderLineStyle = {
        width: `65%`,
        height: '4px',
        position: 'absolute',
        top: '6.8vh',
        left: '0',
        backgroundColor: '#12765e',
        zIndex: '1',
        transition: 'width 500ms ease-out',
    };
    const [maxValue, setMaxValue] = useState(50000);
  
    const handleMaxChange = (event) => {
      setMaxValue(parseInt(event.target.value, 10));
      updateFormData('budget', event.target.value);
    };

    const calculatePercentage = (value, min, max) => ((value - min) / (max - min)) * 100;

    const leftRangeStyle = {
      background: `linear-gradient(to right, green 0%, green ${calculatePercentage(maxValue, 500, 10000)}%, #ccc ${calculatePercentage(maxValue, 500, 10000)}%, #ccc 100%)`,
    };

    // const rightRangeStyle = {
    //     background: `linear-gradient(to right, #ccc ${calculatePercentage(minValue, 500, 10000)}%, #ccc ${calculatePercentage(maxValue, 500, 10000)}%, orange ${calculatePercentage(maxValue, 500, 10000)}%, orange 100%)`,
    // };

    return(
        <>
            <Header dark={true} />
            <div style={{width:"100%", height:"4px", position:"relative"}}>
                <div style={{height:"100%", position:"absolute", top:"6.8vh", left:"0", backgroundColor:"rgb(237, 239, 240)", width:"100%"}}></div>
                <div style={loaderLineStyle}></div>
            </div>
            <div style={{margin:"10vh 7% 0 7%", maxWidth:"100%", display:"flex", flexDirection:"column",flexWrap:"wrap", justifyContent:"center", alignItems:"center"}}>
                <div style={{marginTop:"5%"}}>
                    <div style={{fontWeight:"600", fontSize:"15px"}}>What is your budget per person?  </div>
                </div>

                <div className="range-slider" style={{marginTop:"10%"}}>
                    <span className="rangeValues">${maxValue}</span>
                    <input
                    value={maxValue}
                    min={500}
                    max={10000}
                    step={500}
                    type="range"
                    onChange={handleMaxChange}
                    style={leftRangeStyle}
                    />
                </div>
                <Includes/>
            </div>
            <div style={{position:"fixed",top:"91%", width:"100%",height:"9vh", backgroundColor:"white"}}>
                <a href="/contact/email" style={{color:"white", textDecoration:"none", fontFamily:"Montserrat", fontFamily:"Montserrat"}}>
                    <div style={{position:"fixed",top:"93%", width:"90%", background:"#12765e", height:"5vh", margin:"0 5%", display:"flex", justifyContent:"center", alignContent:"center", alignItems:"center", color:"white", fontSize:"17px", letterSpacing:"4px", boxShadow:"0 0 8px rgba(117, 117, 117, 0.5)", borderRadius:"3px"}}>
                        NEXT
                    </div>
                </a>
            </div>
        </>
    )
}

export default ContactPrice