import { React, useEffect, useState, useRef } from "react";
import BannerVideo from '../assets/banner_tour.mp4'
import BannerVideo1 from '../assets/video1.mp4'
import GoogleFbImg from '../assets/googlefb.png'
import testImg from "../assets/searchHeader.png"
import Header from "./Header";
import '../styles/Homepage.scss'
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import HomepageSlider from "./HomepageSlider";
import HomepageVideoSlider from "./HomepageVideoSlider";
import HomepageBackgroundColorSection from "./HomepageBackgroundColorSection";
import HomepageVacationForTravellers from "./HomepageVacationForTravellers";
import HomeSingleVideo from "./HomeSingleVideo";
import HomepageCreative from "./HomepageCreative";
import creativepng from '../assets/creative.png'
import Footer from "./Footer";
import Form from "./Form";
import icelandImg from '../assets/EuropeanHolidays/iceland.webp';
import turkey01Img from '../assets/EuropeanHolidays/turkey 01.webp';
import italy02Img from '../assets/EuropeanHolidays/italy (2).webp';
import turkey03Img from '../assets/EuropeanHolidays/turkey 03.webp';
import austriaImg from '../assets/EuropeanHolidays/austria.webp';
import norwayImg from '../assets/EuropeanHolidays/norway.webp';
import turkeyImg from '../assets/EuropeanHolidays/turkey.webp';
import finland02Img from '../assets/EuropeanHolidays/finland (2).webp';
import spain01Img from '../assets/EuropeanHolidays/spain 01.webp';
import franceImg from '../assets/EuropeanHolidays/france.webp';
import switzerlandImg from '../assets/EuropeanHolidays/switerland.webp';
import goaImg from '../assets/Domestic/goa.webp';
import pondiImg from '../assets/Domestic/pondi.webp';
import himachal01Img from '../assets/Domestic/himachal 01.webp';
import rajasthanImg from '../assets/Domestic/rajasthan.webp';
import himachal02Img from '../assets/Domestic/himachal 02.webp';
import arunachalImg from '../assets/Domestic/arunachal.webp';
import himachalImg from '../assets/Domestic/himachal.webp';
import tamilnadu01Img from '../assets/Domestic/tamilnadu 01.webp';
import assamImg from '../assets/Domestic/assam.webp';
import jammuImg from '../assets/Domestic/jammu.webp';
import tamilnaduImg from '../assets/Domestic/tamilnadu.webp';
import bengalImg from '../assets/Domestic/bengal.webp';
import keralaImg from '../assets/Domestic/kerala.webp';
import telanganaImg from '../assets/Domestic/telangana.webp';
import delhiImg from '../assets/Domestic/delhi.webp';
import nagalandImg from '../assets/Domestic/nagaland.webp';
import uttarakhandImg from '../assets/Domestic/uttarakhand.webp';
import bahrainImg from '../assets/Middleeast/bahrain.webp';
import qatarImg from '../assets/Middleeast/qatar.webp';
import abuDhabiImg from '../assets/Middleeast/abu dhabi.webp';
import dubaiImg from '../assets/Middleeast/dubai.webp';
import saudiImg from '../assets/Middleeast/saudi.webp';
import melbourneImg from '../assets/Oceania/melbourne.webp';
import perthImg from '../assets/Oceania/perth.webp';
import sydneyImg from '../assets/Oceania/syndey.webp';
import fijiImg from '../assets/Oceania/fijii.webp';
import newZealandImg from '../assets/Oceania/newsland.webp';
import queenslandImg from '../assets/Oceania/quensland.webp';
import tahitiImg from '../assets/Oceania/tahiti.webp';
import baliImg from '../assets/QuickGetaway/bali.jpg';
import dubai1Img from '../assets/QuickGetaway/dubai (1).png';
import dubai2Img from '../assets/QuickGetaway/dubai (2).png';
import maldives00Img from '../assets/QuickGetaway/maldives 00.jpg';
import maldives02Img from '../assets/QuickGetaway/maldives 02.png';
import mauritius01Img from '../assets/QuickGetaway/mauritius 01.png';
import srilanka00Img from '../assets/QuickGetaway/srilanka 00.png';
import thailand01Img from '../assets/QuickGetaway/thailand 01.png';
import finlandImg from '../assets/Scandinavia/finland.webp';
import norway02Img from '../assets/Scandinavia/norway (2).webp';
import sweden02Img from '../assets/Scandinavia/sweden (2).webp';
import denmarkImg from '../assets/Scandinavia/denmark.webp';
import swedenImg from '../assets/Scandinavia/sweden.webp';
import indonesiaImg from '../assets/VisaArrival/indonesia.webp';
import singaporeImg from '../assets/VisaArrival/singapore.webp';
import lakshadweepImg from '../assets/VisaArrival/lakshadweep.webp';
import srilankaImg from '../assets/VisaArrival/srilanka.webp';
import maldivesImg from '../assets/VisaArrival/maldives.webp';
import thailandImg from '../assets/VisaArrival/thailand.webp';
import andamanImg from '../assets/VisaArrival/andaman.webp';
import maritiusImg from '../assets/VisaArrival/maritius.webp';
import vietnamImg from '../assets/VisaArrival/vietnam.webp';
import FooterMenu from "./FooterMenu";
import CarasoulCards from "./CarasoulCards";
import Landscape1 from '../assets/Landscape/landscape1.png'
import Landscape2 from '../assets/Landscape/landscape2.png'
import Landscape3 from '../assets/Landscape/Landscape3.png'
import Landscape4 from '../assets/Landscape/Landscape4.png'
import TrustTheExperts from "./TrustTheExperts";
import NorthIndia from '../assets/North india.png'
import southIndia from '../assets/South india.png'
import HoneyMoon from '../assets/Honeymoon.png'
import spiritual from '../assets/Spiritual.png'
import welcomeFlight from '../assets/welcome flight (7).png'
// import welcomeFlight from '../assets/welcome.png'
import jsonData from '../json/Data.json';
import HomepageBackgroundColorSectionWeb from "./HomepageBackgroundColorSectionWeb";

function Homepage () {
  const VacationForAll = [
    {
      title: "North India",
      img: NorthIndia
    },
    {
      title: "South India",
      img: southIndia
    },
    {
      title: "Honeymoon",
      img: HoneyMoon
    },
    {
      title: "Spiritual",
      img: spiritual
    }
  ]
  const VisaArrivalData = [
    {
      img: indonesiaImg,
      title: "Indonesia",
    },
    {
      img: singaporeImg,
      title: "Singapore",
    },
    {
      img: lakshadweepImg,
      title: "Lakshadweep",
    },
    {
      img: srilankaImg,
      title: "Sri Lanka",
    },
    {
      img: maldivesImg,
      title: "Maldives",
    },
    {
      img: thailandImg,
      title: "Thailand",
    },
    {
      img: andamanImg,
      title: "Andaman",
    },
    {
      img: maritiusImg,
      title: "Maritius",
    },
    {
      img: vietnamImg,
      title: "Vietnam",
    },
  ];
      
  const ScandinaviaData = [
    {
      img: finlandImg,
      title: "Finland",
    },
    {
      img: norway02Img,
      title: "Norway",
    },
    {
      img: sweden02Img,
      title: "Sweden",
    },
    {
      img: denmarkImg,
      title: "Denmark",
    },
    {
      img: icelandImg,
      title: "Iceland",
    },
    {
      img: norwayImg,
      title: "Norway",
    },
    {
      img: swedenImg,
      title: "Sweden",
    },
  ];
      
      const oceaniaData = [
        {
          img: melbourneImg,
          title: "Melbourne",
        },
        {
          img: perthImg,
          title: "Perth",
        },
        {
          img: sydneyImg,
          title: "Sydney",
        },
        {
          img: fijiImg,
          title: "Fiji",
        },
        {
          img: newZealandImg,
          title: "New Zealand",
        },
        {
          img: queenslandImg,
          title: "Queensland",
        },
        {
          img: tahitiImg,
          title: "Tahiti",
        },
      ];
      
      const MiddleEastData = [
        {
          img: bahrainImg,
          title: "Bahrain",
        },
        {
          img: qatarImg,
          title: "Qatar",
        },
        {
          img: abuDhabiImg,
          title: "Abu Dhabi",
        },
        {
          img: dubaiImg,
          title: "Dubai",
        },
        {
          img: saudiImg,
          title: "Saudi",
        },
      ];

    const northIndiaData= [
      {
        id: 1,
        img: goaImg,
        video: "/assets/video/banner_tour.mp4",
        badge: "offer",
        price: 320,
        discount: 210,
        title: "Goa",
        desc: "Lorem ipsum dolor sit amet, consectetur est.",
        offers: "5 days | 6 nights",
        person: "2 person",
      },
      {
        id: 4,
        img: rajasthanImg,
        video: "/assets/video/banner_tour.mp4",
        badge: "offer",
        price: 320,
        discount: 210,
        title: "Rajasthan",
        desc: "Lorem ipsum dolor sit amet, consectetur est.",
        offers: "5 days | 6 nights",
        person: "2 person",
      },
      {
        id: 5,
        img: himachal02Img,
        video: "/assets/video/banner_tour.mp4",
        badge: "offer",
        price: 320,
        discount: 210,
        title: "Himachal",
        desc: "Lorem ipsum dolor sit amet, consectetur est.",
        offers: "5 days | 6 nights",
        person: "2 person",
      },
      {
        id: 6,
        img: arunachalImg,
        video: "/assets/video/banner_tour.mp4",
        badge: "offer",
        price: 320,
        discount: 210,
        title: "Arunachal",
        desc: "Lorem ipsum dolor sit amet, consectetur est.",
        offers: "5 days | 6 nights",
        person: "2 person",
      },
      {
        id: 7,
        img: himachalImg,
        video: "/assets/video/banner_tour.mp4",
        badge: "offer",
        price: 320,
        discount: 210,
        title: "Himachal",
        desc: "Lorem ipsum dolor sit amet, consectetur est.",
        offers: "5 days | 6 nights",
        person: "2 person",
      },
      {
        id: 9,
        img: assamImg,
        video: "/assets/video/banner_tour.mp4",
        badge: "offer",
        price: 320,
        discount: 210,
        title: "Assam",
        desc: "Lorem ipsum dolor sit amet, consectetur est.",
        offers: "5 days | 6 nights",
        person: "2 person",
      },
      {
        id: 10,
        img: jammuImg,
        video: "/assets/video/banner_tour.mp4",
        badge: "offer",
        price: 320,
        discount: 210,
        title: "Jammu",
        desc: "Lorem ipsum dolor sit amet, consectetur est.",
        offers: "5 days | 6 nights",
        person: "2 person",
      },
      {
        id: 12,
        img: bengalImg,
        video: "/assets/video/banner_tour.mp4",
        badge: "offer",
        price: 320,
        discount: 210,
        title: "Bengal",
        desc: "Lorem ipsum dolor sit amet, consectetur est.",
        offers: "5 days | 6 nights",
        person: "2 person",
      },
      {
        id: 15,
        img: delhiImg,
        video: "/assets/video/banner_tour.mp4",
        badge: "offer",
        price: 320,
        discount: 210,
        title: "Delhi",
        desc: "Lorem ipsum dolor sit amet, consectetur est.",
        offers: "5 days | 6 nights",
        person: "2 person",
      },
      {
        id: 16,
        img: nagalandImg,
        video: "/assets/video/banner_tour.mp4",
        badge: "offer",
        price: 320,
        discount: 210,
        title: "Nagaland",
        desc: "Lorem ipsum dolor sit amet, consectetur est.",
        offers: "5 days | 6 nights",
        person: "2 person",
      },
      {
        id: 17,
        img: uttarakhandImg,
        video: "/assets/video/banner_tour.mp4",
        badge: "offer",
        price: 320,
        discount: 210,
        title: "Uttarakhand",
        desc: "Lorem ipsum dolor sit amet, consectetur est.",
        offers: "5 days | 6 nights",
        person: "2 person",
      },
    ]
    const southIndiaData = [
      {
        id: 2,
        img: pondiImg,
        video: "/assets/video/banner_tour.mp4",
        badge: "offer",
        price: 320,
        discount: 210,
        title: "Pondicherry",
        desc: "Lorem ipsum dolor sit amet, consectetur est.",
        offers: "5 days | 6 nights",
        person: "2 person",
      },
      {
        id: 8,
        img: tamilnadu01Img,
        video: "/assets/video/banner_tour.mp4",
        badge: "offer",
        price: 320,
        discount: 210,
        title: "Tamil Nadu",
        desc: "Lorem ipsum dolor sit amet, consectetur est.",
        offers: "5 days | 6 nights",
        person: "2 person",
      }, 
      {
        id: 13,
        img: keralaImg,
        video: "/assets/video/banner_tour.mp4",
        badge: "offer",
        price: 320,
        discount: 210,
        title: "Kerala",
        desc: "Lorem ipsum dolor sit amet, consectetur est.",
        offers: "5 days | 6 nights",
        person: "2 person",
      },
      {
        id: 14,
        img: telanganaImg,
        video: "/assets/video/banner_tour.mp4",
        badge: "offer",
        price: 320,
        discount: 210,
        title: "Telangana",
        desc: "Lorem ipsum dolor sit amet, consectetur est.",
        offers: "5 days | 6 nights",
        person: "2 person",
      },
    ]

    const europeanData = [
      {
        id: 1,
        img: icelandImg,
        video: "/assets/video/banner_tour.mp4",
        badge: "offer",
        price: 320,
        discount: 210,
        title: "Iceland",
        desc: "Lorem ipsum dolor sit amet, consectetur est.",
        offers: "5 days | 6 nights",
        person: "2 person",
      },
      {
        id: 2,
        img: turkey01Img,
        video: "/assets/video/banner_tour.mp4",
        badge: "offer",
        price: 320,
        discount: 210,
        title: "Turkey",
        desc: "Lorem ipsum dolor sit amet, consectetur est.",
        offers: "5 days | 6 nights",
        person: "2 person",
      },
      {
        id: 3,
        img: italy02Img,
        video: "/assets/video/banner_tour.mp4",
        badge: "offer",
        price: 320,
        discount: 210,
        title: "Italy",
        desc: "Lorem ipsum dolor sit amet, consectetur est.",
        offers: "5 days | 6 nights",
        person: "2 person",
      },
      {
        id: 4,
        img: turkey03Img,
        video: "/assets/video/banner_tour.mp4",
        badge: "offer",
        price: 320,
        discount: 210,
        title: "Turkey",
        desc: "Lorem ipsum dolor sit amet, consectetur est.",
        offers: "5 days | 6 nights",
        person: "2 person",
      },
      {
        id: 5,
        img: austriaImg,
        video: "/assets/video/banner_tour.mp4",
        badge: "offer",
        price: 320,
        discount: 210,
        title: "Austria",
        desc: "Lorem ipsum dolor sit amet, consectetur est.",
        offers: "5 days | 6 nights",
        person: "2 person",
      },
      {
        id: 6,
        img: norwayImg,
        video: "/assets/video/banner_tour.mp4",
        badge: "offer",
        price: 320,
        discount: 210,
        title: "Norway",
        desc: "Lorem ipsum dolor sit amet, consectetur est.",
        offers: "5 days | 6 nights",
        person: "2 person",
      },
      {
        id: 7,
        img: turkeyImg,
        video: "/assets/video/banner_tour.mp4",
        badge: "offer",
        price: 320,
        discount: 210,
        title: "Turkey",
        desc: "Lorem ipsum dolor sit amet, consectetur est.",
        offers: "5 days | 6 nights",
        person: "2 person",
      },
      {
        id: 8,
        img: finland02Img,
        video: "/assets/video/banner_tour.mp4",
        badge: "offer",
        price: 320,
        discount: 210,
        title: "Finland",
        desc: "Lorem ipsum dolor sit amet, consectetur est.",
        offers: "5 days | 6 nights",
        person: "2 person",
      },
      {
        id: 9,
        img: spain01Img,
        video: "/assets/video/banner_tour.mp4",
        badge: "offer",
        price: 320,
        discount: 210,
        title: "Spain",
        desc: "Lorem ipsum dolor sit amet, consectetur est.",
        offers: "5 days | 6 nights",
        person: "2 person",
      },
      {
        id: 10,
        img: franceImg,
        video: "/assets/video/banner_tour.mp4",
        badge: "offer",
        price: 320,
        discount: 210,
        title: "France",
        desc: "Lorem ipsum dolor sit amet, consectetur est.",
        offers: "5 days | 6 nights",
        person: "2 person",
      },
      {
        id: 11,
        img: switzerlandImg,
        video: "/assets/video/banner_tour.mp4",
        badge: "offer",
        price: 320,
        discount: 210,
        title: "Switzerland",
        desc: "Lorem ipsum dolor sit amet, consectetur est.",
        offers: "5 days | 6 nights",
        person: "2 person",
      },
    ];
    const newOffersData = [
        {
          id: 1,
          img: testImg,
          video: "/assets/video/banner_tour.mp4",
          badge: "offer",
          price: 320,
          discount: 210,
          title: "hot air balloon",
          desc: "Lorem ipsum dolor sit amet, consectetur est.",
          offers: "5 days | 6 nights",
          person: "2 person",
        },
        {
          id: 1,
          img: testImg,
          badge: "offer",
          price: 320,
          discount: 210,
          title: "Cool water ride",
          desc: "Lorem ipsum dolor sit amet, consectetur est.",
          offers: "5 days | 6 nights",
          person: "2 person",
        },
        {
          id: 1,
          img: testImg,
          badge: "offer",
          price: 320,
          discount: 210,
          title: "water fall",
          desc: "Lorem ipsum dolor sit amet, consectetur est.",
          offers: "5 days | 6 nights",
          person: "2 person",
        },
        {
          id: 1,
          img: testImg,
          badge: "offer",
          price: 320,
          discount: 210,
          title: "Cool water ride",
          desc: "Lorem ipsum dolor sit amet, consectetur est.",
          offers: "5 days | 6 nights",
          person: "2 person",
        },
      ];
      const videoData = [
        {
          id: 1,
          // ZZAA
          // img: Landscape1,
          img: Landscape3,
          badge: "MALDIVES",
          price: 320,
          discount: 210,
          title: "Maldives Package",
          desc: "Lorem ipsum dolor sit amet, consectetur est.",
          offers: "5 days | 6 nights",
          person: "2 person",
        },
        {
          id: 1,
          // ZZAA
          // img: Landscape2,
          img: Landscape4,
          badge: "MALDIVES",
          price: 320,
          discount: 210,
          title: "Spain Package",
          desc: "Lorem ipsum dolor sit amet, consectetur est.",
          offers: "5 days | 6 nights",
          person: "2 person",
        },
      ];
      const quickGetawayData = [
        {
          id: 1,
          img: testImg,
          video: "/assets/video/banner_tour.mp4",
          badge: "offer",
          price: 320,
          discount: 210,
          title: "hot air balloon",
          desc: "Lorem ipsum dolor sit amet, consectetur est.",
          offers: "5 days | 6 nights",
          person: "2 person",
        },
        {
          id: 1,
          img: testImg,
          badge: "offer",
          price: 320,
          discount: 210,
          title: "Cool water ride",
          desc: "Lorem ipsum dolor sit amet, consectetur est.",
          offers: "5 days | 6 nights",
          person: "2 person",
        },
        {
          id: 1,
          img: testImg,
          badge: "offer",
          price: 320,
          discount: 210,
          title: "water fall",
          desc: "Lorem ipsum dolor sit amet, consectetur est.",
          offers: "5 days | 6 nights",
          person: "2 person",
        },
        {
          id: 1,
          img: testImg,
          badge: "offer",
          price: 320,
          discount: 210,
          title: "Cool water ride",
          desc: "Lorem ipsum dolor sit amet, consectetur est.",
          offers: "5 days | 6 nights",
          person: "2 person",
        },
        {
            id: 1,
            img: testImg,
            video: "/assets/video/banner_tour.mp4",
            badge: "offer",
            price: 320,
            discount: 210,
            title: "hot air balloon",
            desc: "Lorem ipsum dolor sit amet, consectetur est.",
            offers: "5 days | 6 nights",
            person: "2 person",
          },
          {
            id: 1,
            img: testImg,
            badge: "offer",
            price: 320,
            discount: 210,
            title: "Cool water ride",
            desc: "Lorem ipsum dolor sit amet, consectetur est.",
            offers: "5 days | 6 nights",
            person: "2 person",
          },
          {
            id: 1,
            img: testImg,
            badge: "offer",
            price: 320,
            discount: 210,
            title: "water fall",
            desc: "Lorem ipsum dolor sit amet, consectetur est.",
            offers: "5 days | 6 nights",
            person: "2 person",
          },
          {
            id: 1,
            img: testImg,
            badge: "offer",
            price: 320,
            discount: 210,
            title: "Cool water ride",
            desc: "Lorem ipsum dolor sit amet, consectetur est.",
            offers: "5 days | 6 nights",
            person: "2 person",
          },
          {
            id: 1,
            img: testImg,
            badge: "offer",
            price: 320,
            discount: 210,
            title: "Cool water ride",
            desc: "Lorem ipsum dolor sit amet, consectetur est.",
            offers: "5 days | 6 nights",
            person: "2 person",
          },
      ];
    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
    
        const init = () => {
          const marquee = document.querySelector('#marquee');
    
            const marqueeInner = marquee.querySelector('.marquee__inner');
            const marqueeContent = marqueeInner.querySelector('.marquee__content');
  
            // Duration
            const duration = marquee.getAttribute('data-marquee-duration');
            // const duration = 2
  
    
            // // Element Clone
            // const marqueeContentClone = marqueeContent.cloneNode(true);
            // marqueeInner.append(marqueeContentClone);
    
            // Marquee animation
            const marqueeContentAll = marqueeInner.querySelectorAll('.marquee__content');
            marqueeContentAll.forEach(element => {
              gsap.to(element, {
                x: '-101%',
                repeat: -1,
                duration: duration,
                ease: 'linear',
              });
            // });
    
            gsap.to('.marquee__inner', {
              xPercent: -10,
              scrollTrigger: {
                trigger: '.section__marquee',
                start: 'top top',
                end: 'bottom top',
                scrub: true,
              },
            });
          });
        };
    
        init();
      },[]);

      useEffect(() => {
            const allPlaces = jsonData.map(item => item.places_covered.split(',')
            .map(place => place.trim()) // Trim spaces from the beginning and end
            .map(place => place.charAt(0).toUpperCase() + place.slice(1).toLowerCase()) // Capitalize first letter
        ).flat();
            const uniquePlaces = [...new Set(allPlaces)]; 
            setSuggestions(uniquePlaces)
      }, []);


      const [searchInput, setSearchInput] = useState('');
      const [suggestions, setSuggestions] = useState(['Delhi', 'Amritsar', 'Mumbai', 'Jaipur', 'Chennai', 'Bangalore']);
      const [showSuggestions, setShowSuggestions] = useState(false);
      const dropdownRef = useRef(null);

      const handleInputChange = (event) => {
        const inputValue = event.target.value.toLowerCase();
        setSearchInput(inputValue);
        handleSearchClick()
      };


      const handleSearchClick = () => {
        // Filter and set matching suggestions
        const matchingSuggestions = suggestions.filter(suggestion => suggestion.toLowerCase().includes(searchInput));
        setSuggestions(matchingSuggestions);
        
        // Show suggestions
        setShowSuggestions(true);
      };

      const handleSuggestionClick = (suggestion) => {
        // Set selected suggestion in the input
        setSearchInput(suggestion);
        // Clear suggestions and hide dropdown
        setSuggestions([]);
        // setShowSuggestions(false);
      };
      // useEffect(() => {
      //   const handleClickOutside = (event) => {
      //     if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      //       // Clicked outside the dropdown, handle accordingly (e.g., close dropdown)
      //     }
      //   };
      
      //   document.addEventListener('mousedown', handleClickOutside);
      
      //   return () => {
      //     document.removeEventListener('mousedown', handleClickOutside);
      //   };
      // }, [dropdownRef]);

    return(
        <>  
            <div style={{minWidth:"100%", position:"relative", display:"block", overflowX:"hidden", backgroundColor:"#f8f8f8"}}>
                {/* ZZAA */}
                <Header dark={true}/>
                {/* <Header/> */}
                <div style={{top:"0", left:"0", width:"100%" }}>
                    <img className="main-img" style={{maxWidth:"100%", objectFit:"cover",minWidth:"100%" }} src={baliImg}/>
                    <div style={{position:"absolute", top:"0", left:"0", width:"100%", height:"100%", background:"rgba(0, 0, 0, 0.5)", pointerEvents:"none"}}></div>
                </div>
                <div style={{position:"absolute", top:"58%", left:"50%", transform:"translate(-50%, -50%)", color:"white", textAlign:"center", minWidth:"90%", display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
                    <div style={{fontFamily:"Kalnia", fontWeight:"bold"}} className="font-size-72-26">
                        {/* Plan your next <span style={{fontFamily:"Dancing Script", color:"#12765e"}}>Hassle-free</span> holiday */}
                        Your Dream Vacation<br/>Is Finally Here
                    </div>
                    <div style={{marginTop:"20px", border:"3px solid white", backgroundColor:"white", textAlign:"left", padding:"0 5%", borderRadius:"4px"}} className="home-banner-white-box">
                        <a href="#" style={{textDecoration:"none", color:"black"}}>
                                <div style={{borderBottom:"1px solid lightgrey"}}><div className="destination-word-homepage">DESTINATION </div></div>
                                <div className="font-size-24-18 where-like-go-homepage">Where would you like to go?</div>
                            <div className="flex-row-column">
                              <div className="search-box-home" style={{backgroundColor:"white", display:"flex", alignItems:"center", justifyContent:"flex-start", padding:"2% 5%", border:"1px solid grey", borderRadius:"4px"}}>
                                  {/* <i class="fa fa-search" aria-hidden="true"></i> */}
                                  <div style={{ position: 'relative', width:"100%" }}>
                                        {/* Search input */}
                                        <input
                                          type="search"
                                          placeholder="e.g. Delhi, Amritsar"
                                          value={searchInput}
                                          onChange={handleInputChange}
                                          onClick={handleSearchClick}
                                          style={{ border: 'none', minWidth: '100%', minHeight: '100%', backgroundColor: 'white' }}
                                        />

                                        {/* Suggestions dropdown */}
                                        {showSuggestions && suggestions.length > 0 && (
                                          <div
                                            style={{
                                              position: 'absolute',
                                              border: '1px solid #ccc',
                                              maxHeight: '150px',
                                              overflowY: 'auto',
                                              width: '100%',
                                              backgroundColor: 'white',
                                              zIndex: "100000000", // Ensure the dropdown appears above other elements
                                            }}
                                          >
                                            <ul style={{textDecoration:"none", listStyle:"none", minWidth:"100%"}}>
                                            {suggestions.map((suggestion, index) => (
                                              <div style={{display:"flex", flexDirection:"column"}}>
                                                  <a
                                                    key={index}
                                                    style={{ padding: '8px', cursor: 'pointer', minWidth:"100%" }}
                                                    onClick={() => handleSuggestionClick(suggestion)}
                                                    href={`/search/${suggestion}`}
                                                  >
                                                    {suggestion}
                                                  </a>
                                              </div>
                                            ))}
                                            </ul>
                                          </div>
                                        )}
                                      </div>
                                  {/* <svg style={{fill:"#12765e"}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cone-striped" viewBox="0 0 16 16"> <path d="m9.97 4.88.953 3.811C10.159 8.878 9.14 9 8 9c-1.14 0-2.158-.122-2.923-.309L6.03 4.88C6.635 4.957 7.3 5 8 5s1.365-.043 1.97-.12zm-.245-.978L8.97.88C8.718-.13 7.282-.13 7.03.88L6.275 3.9C6.8 3.965 7.382 4 8 4c.618 0 1.2-.036 1.725-.098zm4.396 8.613a.5.5 0 0 1 .037.96l-6 2a.5.5 0 0 1-.316 0l-6-2a.5.5 0 0 1 .037-.96l2.391-.598.565-2.257c.862.212 1.964.339 3.165.339s2.303-.127 3.165-.339l.565 2.257 2.391.598z"/> </svg> */}
                              </div>
                              <div className="plan-your-trip-btn" style={{backgroundColor:"#11866C", display:"flex", alignItems:"center", justifyContent:"center", padding:"2% 2%", maxHeight:"30px", color:"white", borderRadius:"4px", boxShadow: "0 1px 4px 0 rgba(63,65,68,.3)", fontWeight:"bolder"}}>
                                <div style={{padding:"3% 0"}}>PLAN YOUR TRIP</div>
                                  {/* <input type="search" placeholder="Search" style={{border:"none", minWidth:"80%", minHeight:"30px"}}/> */}
                                  {/* <svg style={{fill:"#12765e"}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cone-striped" viewBox="0 0 16 16"> <path d="m9.97 4.88.953 3.811C10.159 8.878 9.14 9 8 9c-1.14 0-2.158-.122-2.923-.309L6.03 4.88C6.635 4.957 7.3 5 8 5s1.365-.043 1.97-.12zm-.245-.978L8.97.88C8.718-.13 7.282-.13 7.03.88L6.275 3.9C6.8 3.965 7.382 4 8 4c.618 0 1.2-.036 1.725-.098zm4.396 8.613a.5.5 0 0 1 .037.96l-6 2a.5.5 0 0 1-.316 0l-6-2a.5.5 0 0 1 .037-.96l2.391-.598.565-2.257c.862.212 1.964.339 3.165.339s2.303-.127 3.165-.339l.565 2.257 2.391.598z"/> </svg> */}
                              </div>
                            </div>
                        </a>
                    </div>

                </div>
                <section style={{marginTop:"-5px", backgroundColor:"black", color:"white", whiteSpace:"nowrap", minHeight:"2em", maxHeight:"2em", overflowX:"visible", zIndex:"1"}}>
                    <div id="marquee" data-marquee-duration="20">
                    <div className="marquee__inner">
                        <div className="marquee__content" style={{fontSize:"18px", display:"flex", alignItems:"center", whiteSpace:"nowrap" , zIndex:"1"}}>
                  
                            <img src={GoogleFbImg} style={{maxWidth:"50px", padding:"0 10px"}} /> 4.5
                            {/* <svg style={{fill:"#12765e"}} width="50%" height="50%" viewBox="0 0 10 10" ><path d="M7.107 1.909a1 1 0 011.786 0l1.528 3.027 3.364.475a1 1 0 01.543 1.72l-2.413 2.26.587 3.288a1 1 0 01-1.434 1.069L8 12.204l-3.068 1.544a1 1 0 01-1.434-1.07l.587-3.288-2.413-2.258a1 1 0 01.543-1.72l3.364-.476L7.107 1.91z"></path></svg> */}
                            <span style={{padding:"0 10px"}}>Rated</span>
                            <svg style={{maxWidth:"18px", maxHeight:"18px", marginLeft:"60px"}} fill="#12765e" height="800px" width="800px" version="1.1" id="Capa_1" viewBox="0 0 191.667 191.667" ><g id="SVGRepo_bgCarrier" stroke-width="0"/><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/><g id="SVGRepo_iconCarrier"> <path d="M95.833,0C42.991,0,0,42.99,0,95.833s42.991,95.834,95.833,95.834s95.833-42.991,95.833-95.834S148.676,0,95.833,0z M150.862,79.646l-60.207,60.207c-2.56,2.56-5.963,3.969-9.583,3.969c-3.62,0-7.023-1.409-9.583-3.969l-30.685-30.685 c-2.56-2.56-3.97-5.963-3.97-9.583c0-3.621,1.41-7.024,3.97-9.584c2.559-2.56,5.962-3.97,9.583-3.97c3.62,0,7.024,1.41,9.583,3.971 l21.101,21.1l50.623-50.623c2.56-2.56,5.963-3.969,9.583-3.969c3.62,0,7.023,1.409,9.583,3.969 C156.146,65.765,156.146,74.362,150.862,79.646z"/> </g></svg>
                            <span style={{padding:"0 10px"}}>100% Customized Trips</span>
                            <svg style={{maxWidth:"18px", maxHeight:"18px", marginLeft:"60px"}} fill="#12765e" height="800px" width="800px" version="1.1" id="Capa_1" viewBox="0 0 191.667 191.667" ><g id="SVGRepo_bgCarrier" stroke-width="0"/><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/><g id="SVGRepo_iconCarrier"> <path d="M95.833,0C42.991,0,0,42.99,0,95.833s42.991,95.834,95.833,95.834s95.833-42.991,95.833-95.834S148.676,0,95.833,0z M150.862,79.646l-60.207,60.207c-2.56,2.56-5.963,3.969-9.583,3.969c-3.62,0-7.023-1.409-9.583-3.969l-30.685-30.685 c-2.56-2.56-3.97-5.963-3.97-9.583c0-3.621,1.41-7.024,3.97-9.584c2.559-2.56,5.962-3.97,9.583-3.97c3.62,0,7.024,1.41,9.583,3.971 l21.101,21.1l50.623-50.623c2.56-2.56,5.963-3.969,9.583-3.969c3.62,0,7.023,1.409,9.583,3.969 C156.146,65.765,156.146,74.362,150.862,79.646z"/> </g></svg>
                            <span style={{padding:"0 10px"}}>95% Visa Success Rate</span>
                            <svg style={{maxWidth:"18px", maxHeight:"18px", marginLeft:"60px"}} fill="#12765e" height="800px" width="800px" version="1.1" id="Capa_1" viewBox="0 0 191.667 191.667" ><g id="SVGRepo_bgCarrier" stroke-width="0"/><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/><g id="SVGRepo_iconCarrier"> <path d="M95.833,0C42.991,0,0,42.99,0,95.833s42.991,95.834,95.833,95.834s95.833-42.991,95.833-95.834S148.676,0,95.833,0z M150.862,79.646l-60.207,60.207c-2.56,2.56-5.963,3.969-9.583,3.969c-3.62,0-7.023-1.409-9.583-3.969l-30.685-30.685 c-2.56-2.56-3.97-5.963-3.97-9.583c0-3.621,1.41-7.024,3.97-9.584c2.559-2.56,5.962-3.97,9.583-3.97c3.62,0,7.024,1.41,9.583,3.971 l21.101,21.1l50.623-50.623c2.56-2.56,5.963-3.969,9.583-3.969c3.62,0,7.023,1.409,9.583,3.969 C156.146,65.765,156.146,74.362,150.862,79.646z"/> </g></svg>
                            <span style={{padding:"0 10px"}}>24x7 Concierge</span>
                        </div>
                    </div>
                    </div>
                </section>
            </div>
            
            <div style={{background:"#f8f8f8"}}>
              <HomepageSlider dataSlider={northIndiaData} title="North India Tours"/>
              <HomepageSlider dataSlider={southIndiaData} title="South India Tours"/>
              <HomepageSlider dataSlider={VisaArrivalData} title="Visa on arrival destinations"/>
              <CarasoulCards/>
              {/* ZZAA */}
              {/* <div className="flex-row-column" style={{justifyContent:"center", alignContent:"center", alignItems:"center"}}>
                <HomepageVideoSlider data={videoData}/>
              </div> */}
              <HomepageBackgroundColorSection title="QUICK GETAWAY DESTINATIONS" description="Ideal for 3-5 days trip" data={europeanData.slice(2,11)}/>
              <HomepageBackgroundColorSectionWeb title="QUICK GETAWAY DESTINATIONS" description="Ideal for 3-5 days trip" data={europeanData.slice(2,11)}/>
              <HomepageSlider dataSlider={MiddleEastData.slice(1, 4)} title="TOURISM BOARD PARTNERS"/>
              <HomepageBackgroundColorSection title="EXPLORATIONS FAR AWAY" description="Ideal for 5-14 days trip" data={oceaniaData.slice(1,7)}/>
              <HomepageBackgroundColorSectionWeb title="EXPLORATIONS FAR AWAY" description="Ideal for 5-14 days trip" data={oceaniaData.slice(1,7)}/>
              {/* <HomeSingleVideo data={videoData}/> */}
              <HomepageSlider dataSlider={oceaniaData} title="OCEANIA"/>
              <HomepageSlider dataSlider={europeanData} title="EUROPEAN HOLIDAYS"/>
              <div className="home-travellers-head">
                  <HomepageVacationForTravellers title="HOLIDAYS FOR ALL" description="Budget" data={VisaArrivalData.slice(5,9)} />
              </div>
              <HomepageSlider dataSlider={VisaArrivalData} title="SOUTH EAST ASIAN VACATIONS"/>
              <HomepageCreative data={welcomeFlight}/>
              <HomepageSlider dataSlider={ScandinaviaData} title="Scandinavia"/>
              <div className="home-travellers-head">
                <HomepageVacationForTravellers title="VACATION FOR ALL" description="Travellers" data={VacationForAll} />
              </div>
              <HomepageSlider dataSlider={MiddleEastData} title="MIDDLE EAST"/>
              <TrustTheExperts/>
              {/* <Form/> */}
              
              <div style={{marginBottom:"5%", minHeight:"100px"}}>
                <a href="/contact/enquiry" style={{textDecoration:"none", color:"white", fontFamily:"Lato", fontWeight:"700"}}><button style={{position:"relative"}} type="button" class="main-btn-rect popup-btn contact-btn-home">Contact Us!</button></a>
              </div>

              <FooterMenu/>
              <Footer/>
            </div>
        </>
    )
};

export default Homepage;
