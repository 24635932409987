import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import SearchPage from './components/SearchPage';
import Homepage from './components/Homepage';
import MultiStepForm3 from './components/MultiAStepForm3';
import ContactDuration from './components/ContactDuration';
import ContactDates from './components/ContactDates';
import ContactPlace from './components/ContactPlace';
import ContactTravel from './components/ContactTravel';
import ContactBudget from './components/ContactBudget';
import ContactPrice from './components/ContactPrice';
import ContactEmail from './components/ContactEmail';
import Header from './components/Header';
import { FormProvider } from './components/FormContext';
import PackageDetail from './components/PackageDetail';
import jsonData from './json/Data.json';
import { useState, useEffect } from 'react';
import ContactContiki from './components/ContactContiki';

function App() {
  const [formData, setFormData] = useState(() => {
    // Initialize form data from local storage or default values
    const storedData = localStorage.getItem('formData');
    return storedData ? JSON.parse(storedData) : {
      how_many_people: "",
      how_many_weeks: '',
      travel_when: '',
      travel_where: '',
      budget: 0,
      firstname: '',
      lastname: '',
      email:'',
      trip:'',
      mobile:'',
      contactBy:'',
      package_name:''
    };
  });

  const updateFormData = (key, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  useEffect(() => {
    localStorage.setItem('formData', JSON.stringify(formData));
  }, [formData]);

  return (
    <FormProvider>
      <>
        <Header dark={true}/>
        <BrowserRouter>
          <Routes>
            <Route path='/search/:location' element={<SearchPage />} ></Route>
            <Route path='/search' element={<SearchPage />} ></Route>
            <Route path='/' element={<Homepage />} ></Route>
            <Route path='/contact' element={<MultiStepForm3 formData={formData} updateFormData={updateFormData}/>}></Route>
            <Route path='/contact/duration' element={<ContactDuration formData={formData} updateFormData={updateFormData}/>}></Route>
            <Route path='/contact/dates' element={<ContactDates formData={formData} updateFormData={updateFormData}/>}></Route>
            <Route path='/contact/place' element={<ContactPlace formData={formData} updateFormData={updateFormData}/>}></Route>
            {/* <Route path='/contact/travel' element={<ContactTravel/>}></Route> */}
            {/* <Route path='/contact/budget' element={<ContactBudget/>}></Route> */}
            <Route path='/contact/price' element={<ContactPrice formData={formData} updateFormData={updateFormData}/>}></Route>
            <Route path='/contact/email' element={<ContactEmail formData={formData} updateFormData={updateFormData}/>}></Route>
            <Route path='/contact/:packageName' element={<ContactContiki formData={formData} updateFormData={updateFormData}/>}></Route>
            {jsonData.map((packageData) => (
              <Route
                key={packageData.package_name}
                path={`/package/${packageData.package_name}`}
                element={<PackageDetail packageData={packageData} />}
              />
            ))}
          </Routes>
        </BrowserRouter>
      </>
    </FormProvider>
  );
}

export default App;
